import React, { useEffect, useState } from "react";
import classes from "./ArtistDetails.module.scss";
import ArtistCard from "../components/artists/ArtistCard";
import { get_request } from "../utils/ApiHandlers";
import Oval from "react-loading-icons/dist/esm/components/oval";
import gs from "../assets/gs.jpg";
import YouTube from "react-youtube";
import {useNavigate, useParams} from "react-router-dom";
import ArtworkCard from "../components/artists/ArtworkCard";

import { marked } from 'marked';
import {useSelector} from "react-redux";
import ScrollTop from "../utils/ScrollTop";

marked.setOptions({
  breaks: true,
  gfm: true,
  sanitize: true
});


const ArtistDetails = (props) => {
  ScrollTop()
  props.changePage("artists")

  const navigate = useNavigate();

  const [artistDetails, setArtistDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { artist_id } = useParams();

  useEffect(() => {
    const fetchArtistDetails = async () => {
      const response = await get_request(navigate, `/api/v1/artists/${artist_id}`);
      if (response.status === 200) {
        setArtistDetails(response.data);
        setIsLoading(false);
      }
    };
    fetchArtistDetails();
  }, []);

  const getVideoId = (url) => {
    const urlObj = new URL(url);
    return urlObj.searchParams.get("v");
  };

  const getMarkdownHtml = (markdown) => {
    return marked(markdown, { sanitize: true });
  };

  return (
      <div
          className={[
            `${classes.artist_details} ${
                props.currentPage ? classes.in : classes.out
            }`,
          ]}
      >
        <section className={classes.section1}>
          <div className={classes.image_container}>
            <img
                className={classes.image}
                src={artistDetails && artistDetails.profileImage.url}
            />
          </div>
          <div className={classes.text_container}>
            <h1 className={classes.artist_name} style={{marginBottom: 20}}>
              {artistDetails && artistDetails.fullName}
            </h1>
            <span className={classes.artist_text}>
                        {artistDetails && (
                            <div
                                dangerouslySetInnerHTML={{
                                  __html: getMarkdownHtml(artistDetails.summary),
                                }}
                            />
                        )}
          </span>
          </div>
        </section>
        <hr className={classes.hr}/>
        <section className={classes.section2}>
          <div className={classes.story_container}>
            <h2 className={classes.awards_header}>Hakkında</h2>
            <span>
            {artistDetails && (
                <div
                    dangerouslySetInnerHTML={{
                      __html: getMarkdownHtml(artistDetails.biography),
                    }}
                />
              )}
          </span>
          </div>
        </section>
        {artistDetails?.videoUrls?.length > 0 && (
            <section className={classes.section2}>
              <div className={classes.awards_container}>
                <h2 className={classes.awards_header}>Ödüller</h2>
                <div className={classes.video_grid}>
                  {artistDetails.videoUrls.map((video, index) => (
                      <div key={index} className={classes.video_container}>
                        <h4 className={classes.awards_header}>{video.header}</h4>
                        <YouTube
                            className={classes.video}
                            opts={{height: 300, width: "100%"}}
                            videoId={getVideoId(video.url)} // defaults -> ''
                        />
                      </div>
                  ))}
                </div>
              </div>
            </section>
        )}
        <hr className={classes.hr}/>
        {artistDetails?.workAssets?.length > 0 && (
            <section className={classes.section3}>
              <h2
                  style={{
                    color: "#a11919",
                    fontSize: 20,
                    fontWeight: 600,
                    marginBottom: 20,
                    letterSpacing: 1.5,
                  }}
              >
                eserler
              </h2>
              <div className={classes.artwork_container}>
                {artistDetails.workAssets.map((asset) => {
                  return <ArtworkCard imageUrl={asset.url}/>;
                })}
              </div>
            </section>
        )}
      </div>
  );
};

export default ArtistDetails;

import React, { useRef } from "react";
import classes from "./Login.module.scss";
import { useNavigate } from "react-router-dom";
import { post_request } from "../utils/ApiHandlers";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../store/userSlice";
import toastr from "toastr";
import ScrollTop from "../utils/ScrollTop";

const Login = (props) => {
  ScrollTop()
  props.changePage("login", null, true)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const emailRef = useRef();
  const passwordRef = useRef();
  const previousPage = useSelector((state) => state.ui.previous);


  const loginHandler = async (e) => {
    e.preventDefault();

    const response = await post_request(navigate, "/api/v1/auth", {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    });
    if (response.status === 200) {
      dispatch(userActions.userLogin(response.data));
      navigate(previousPage);

    }
  };

  return (
    <div
      className={[
        `${classes.login} ${props.currentPage ? classes.in : classes.out}`,
      ]}
    >
      <form className={classes.login__form} onSubmit={loginHandler}>
        <span className={classes.login__form__title}>üye girişi</span>
        <input
          className={classes.login__form__input}
          type="text"
          placeholder="e-posta"
          ref={emailRef}
        ></input>
        <input
          className={classes.login__form__input}
          type="password"
          placeholder="şifre"
          ref={passwordRef}
        ></input>
        <input
          className={[
            `${classes.login__form__input} ${classes.login__form__btn}`,
          ]}
          type="submit"
          placeholder="giriş yap"
          value="giriş yap"
        ></input>
        <span className={classes.span}>
          Henüz üyeliğiniz yok mu? &nbsp;
          <a
            className={classes.login__form__login}
            onClick={() => {
              props.changePage("signup");
              setTimeout(() => {
                navigate("/signup");
              }, 500);
            }}
          >
            kayıt olun
          </a>
        </span>
      </form>
    </div>
  );
};

export default Login;

import toastr from "toastr";
import userSlice from "../store/userSlice";

export function HandleApiError(error, showToastr = true) {
    console.log(error)

    const LogOut = () => {
        sessionStorage.setItem("user", JSON.stringify(userSlice.getInitialState()));
    };

    const message = error.response.data.message || 'Bir hata oluştu.';
    if (error.response) {
        if (showToastr) {
            if (error.response.data.errors && error.response.data.errors.length > 0) {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                    console.log(error.response.data.errors[i]);
                    toastr.error(error.response.data.errors[i]);
                }
            } else {
                toastr.error(message);
            }
        }
        if (error.response.status === 401 || error.response.status === 403) {
            LogOut();
            window.location.replace('/login');
        }

    } else {
        toastr.error('Beklenmeyen hata :(');
    }
}


import React, { useEffect, useState } from "react";
import classes from "./ProductCard.module.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatPrice } from "../../utils/Formatters";
import ConfirmationModal from "../modals/Comfirmation";
import {post_request} from "../../utils/ApiHandlers";
import toastr from "toastr";
import {marked} from "marked";


const ProductCard = (props) => {
    const user = useSelector((state) => state.user.user);
    const [productState, setProductState] = useState(props.state);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const navigate = useNavigate();
    const handlePurchaseClick = () => {
        setIsModalVisible(true);
    };

    const handleConfirmPurchase = async () => {
        setIsModalVisible(false);
        const response = await post_request(navigate, `/api/v1/products/buy/${props.id}` ,{}, user)
        if (response.status === 200) {
            setProductState("SOLD");
            toastr.success("Sizinle en kısa zamanda iletişime geçeceğiz :)");
        }
    };

    const getMarkdownHtml = (markdown) => {
        return marked(markdown, { sanitize: true });
    };

    const handleCancelPurchase = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        const priceElement = document.getElementById(props.id);
        if (priceElement) {
            priceElement.classList.add(classes.change);
        }

        setTimeout(() => {
            if (priceElement) {
                priceElement.classList.remove(classes.change);
            }
        }, 500);
    }, [props.currentPrice]);
    return (
        <div
            className={classes.product_card}
        >
            <img
                onClick={() =>
                    props.openImageFullScreen(props.imageUrls && props.imageUrls, props.name && props.name)
                }
                src={props.imageUrls && props.imageUrls[0].url}
                className={classes.product_card__img}
            />
            <span className={classes.text_container}>
                <span className={classes.text_bold}>{props.name}</span>
                <span className={classes.text_bold}>{props.artistName}</span>
                <span className={classes.text_normal} style={{fontFamily: "Gilroy-Thin"}}>{props.category}</span>
                <span className={classes.text_normal} dangerouslySetInnerHTML={{__html: getMarkdownHtml(props.description)}}></span>
                <span className={classes.text_normal}>{formatPrice(props.price.toString())} TL</span>
            </span>
            <div className={classes.product_card__btn_container}>
            <div style={{ height: "45%"}}>
            {productState === "ON_SALE" ? (
                    user.token ?
                        (
                            <>
                                <button onClick={handlePurchaseClick} className={classes.product_card__btn_container__btn}>
                                    satın al
                                </button>

                            </>
                        ) : (
                            <>
                                <button
                                    className={classes.product_card__btn_container__btn}
                                    onClick={() => navigate("/login")}>
                                    satın almak için giriş yap
                                </button>
                            </>
                        )


                ) : (
                    <div className={classes.banner}>satıldı</div>
                )}
            </div>
            <div style={{ height: "45%"}}>
                <button
                    style={{width: "100%"}}
                    className={classes.product_card__btn_container__btn}
                    onClick={() =>
                        navigate(`/hemen-al/${props.id}`)
                    }
                >
                    eser detay
                </button>
            </div>
            </div>

            <ConfirmationModal
                isVisible={isModalVisible}
                onConfirm={handleConfirmPurchase}
                onCancel={handleCancelPurchase}
                message={`"${props.name}" adlı eseri KDV dahil ${formatPrice((props.price * 1.2).toString())} TL karşılığında satın almayı onaylıyor musunuz?`}
            />
        </div>
    );
};

export default ProductCard;

import React, { useEffect, useState } from "react";
import classes from "./Verification.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { MdVerified } from "react-icons/md";
import { MdOutlineBlock } from "react-icons/md";
import Oval from "react-loading-icons/dist/esm/components/oval";
import {get_request, post_request} from "../utils/ApiHandlers";
import toastr from "toastr";
import ScrollTop from "../utils/ScrollTop";
const Verification = (props) => {
  ScrollTop()

  const navigate = useNavigate();

  const { token } = useParams();
  const { userId } = useParams();
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verifiyUser = async () => {
      const response = await get_request(navigate, `/api/v1/verification/${token}`);
      if (response.data.verified) {
        setLoading(false);
        setSuccess(true);
      } else if (!response.data.verified) {
        setSuccess(false);
        setLoading(false);
      } else {
        return null;
      }
      return response;
    };

    verifiyUser();
  }, []);


const resendEmailHandler = async () => {
  const response = await post_request(navigate, `/api/v1/verification/resend/${userId}`);
    if (response.status === 200) {
        toastr.success("Mail başarıyla gönderildi");
    }
}

  return (
    <div
      className={[
        `${classes.verification} ${
          props.currentPage ? classes.in : classes.out
        }`,
      ]}
    >
      <div className={classes.modal}>
        <span className={classes.modal__title}>Hesap doğrulama</span>
        {success && success != null && (
          <>
            <MdVerified color="green" size={100} />
            <span className={classes.modal__message}>
              hesabınız başarıyla doğrulandı.
            </span>
            <span
              className={classes.span}
              onClick={() => {
                navigate("/login");
              }}
            >
              giriş yap
            </span>
          </>
        )}
        {!success && success != null && (
          <>
            <MdOutlineBlock color="red" size={100} />
            <span className={classes.modal__message}>
              doğrulama başarısız oldu.
            </span>
            <span
              className={classes.span}
              onClick={resendEmailHandler}
            >
              tekrar mail gönder
            </span>
          </>
        )}
        {loading && (
          <div className={classes.spin_container}>
            <Oval stroke="black" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Verification;

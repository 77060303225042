import React, {useEffect, useState} from "react";
import classes from "./Navbar.module.scss";
import "../notifications/notification.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { FaCaretDown, FaBell } from "react-icons/fa";
import { userActions } from "../../store/userSlice";
import HamburgerMenu from "./HamburgerMenu";
import {delete_request, get_request, put_request} from "../../utils/ApiHandlers";
import Notifications from "../notifications/Notifications";
const Navbar = (props) => {
  const width = window.innerWidth;
  const page = useSelector((state) => state.ui.page);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const navigate = useNavigate();


    useEffect(() => {

    }, []);


    const markAsRead = async (notificationIds) => {
        try {
            await put_request(navigate, `/api/v1/notifications/${notificationIds}`, user);
            setNotifications(prev =>
                prev.map(notif =>
                    notificationIds.includes(notif.id) ? { ...notif, read: true } : notif
                )
            );
            setUnreadCount(prev => prev - notificationIds.length);
        } catch (error) {
            console.error("Error marking notifications as read:", error);
        }
    };

    const deleteNotification = async (notificationId) => {
        try {
            await delete_request(navigate, `/api/v1/notifications/${notificationId}`, user);
            setNotifications(prev =>
                prev.filter(notif => notif.id !== notificationId)
            );
            if (!notifications.find(notif => notif.id === notificationId).read) {
                setUnreadCount(prev => prev - 1);
            }
        } catch (error) {
            console.error("Error deleting notification:", error);
        }
    };



  return (
    <div className={classes.nav}>
      <HamburgerMenu />
      <div className={classes.logoContainer}>
        <img
          className={classes.logoContainer__logo}
          onClick={() => {
            navigate("/");
          }}
          src={logo}
        />
      </div>
      <div className={classes.page_buttons_container}>
        <span
          className={classes.page_btn}
          onClick={() => {
            navigate("/auctions");
          }}
          style={{
            fontWeight: page === "auctions" && "bold",
            color: page === "auctions" && "black",
          }}
        >
          müzayedeler
        </span>
        <span
            className={classes.page_btn}
            onClick={() => {
                navigate("/hemen-al");
            }}
            style={
                {
                    fontWeight: page === "products" && "bold",
                    color: page === "products" && "black"
                }
            }
        >hemen al
        </span>
        <span
          className={classes.page_btn}
          style={{
            fontWeight: page === "artists" && "bold",
            color: page === "artists" && "black",
          }}
          onClick={() => {
            navigate("/artists");
          }}
        >
          sanatçılar
        </span>
        <span
            className={classes.page_btn}
            style={{
                fontWeight: page === "blog" && "bold",
                color: page === "blog" && "black",
            }}
            onClick={() => {
                navigate("/blog");
            }}
        >
            blog
        </span>
        <span className={classes.page_btn_light}>iletişim</span>
        <span className={classes.page_btn_light}>hakkımızda</span>
      </div>
      {!user.loggedIn ? (
        <div className={classes.ls_btn_container}>
          <span
            className={classes.ls_btn_container__icon}
            style={{
              fontWeight: page === "login" && "bold",
              color: page === "login" && "black",
            }}
            onClick={() => {
              navigate("/login");
            }}
          >
            giriş
          </span>
          <span className={classes.ls_btn_container__icon_splitter}></span>
          <span
            className={[
              ` ${classes.signup_btn} ${classes.ls_btn_container__icon}`,
            ]}
            style={{
              fontWeight: page === "signup" && "bold",
              color: page === "signup" && "black",
            }}
            onClick={() => {
              navigate("/signup");
            }}
          >
            kayıt
          </span>
        </div>
      ) : (
          <div className={classes.user_actions}>
              <div className={classes.notification}>
                  <Notifications/>
              </div>
              <div className={classes.nav__menu}>
                  <div className={classes.nav__btn}>
                <span
                    className={classes.btn_name}
                    style={{marginRight: 10, color: "black"}}
                >
                  {!user.admin ? (
                      <>
                          {user.user.name && user.user.name}
                          {user.user.surname && width > 450 && ` ${user.user.surname}`}
                      </>
                  ) : (
                      "admin"
                  )}

                </span>
                      <FaCaretDown
                          className={classes.profile_icon}
                          size={15}
                          color="black"
                      />
                  </div>
                  <ul className={`${classes["dropdown_item_container"]}`}>
                      {["Profilim", "Çıkış yap"].map((val, index) => (
                          <li
                              onClick={() => {
                                  if (index === 0) {
                                      navigate("/hesabım")
                                  } else if (index === 1) {
                                      dispatch(userActions.userLogout());
                                      navigate("/login");
                                  }
                              }}
                              key={index}
                              className={classes["nav__menu-items"]}
                          >
                              {val}
                          </li>
                      ))}
                  </ul>
              </div>
          </div>
      )}
    </div>
  );
};

export default Navbar;

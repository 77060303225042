import React, {useEffect, useState} from "react";
import classes from "./HomepageTimer.module.scss";
import { useTimer } from "react-timer-hook";

const HomepageTimer = (props) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const expiryTimestamp = new Date(props.expiryTimestamp);


    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({
        expiryTimestamp,
        onExpire: () => console.warn("onExpire called"),
    });

    useEffect(() => {
        if (expiryTimestamp.getTime() !== 0 && !isInitialized) {
            setIsInitialized(true);
            restart(expiryTimestamp);
        }
    }, [expiryTimestamp, isInitialized, restart]);


    return (
        <div className={classes.container}>
            <span>son</span>
            <div className={classes.timer}>
    <span>
      <b>{String(days).padStart(2, '0').split('')[0]}</b>
      <b>{String(days).padStart(2, '0').split('')[1]}</b>
    </span> <p>gün</p>
                <span>
      <b>{String(hours).padStart(2, '0').split('')[0]}</b>
      <b>{String(hours).padStart(2, '0').split('')[1]}</b>
                </span> <p>saat</p>
                <span>
      <b>{String(minutes).padStart(2, '0').split('')[0]}</b>
      <b>{String(minutes).padStart(2, '0').split('')[1]}</b>
                </span> <p>dakika</p>
            </div>
        </div>
    );
};

export default HomepageTimer;

import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: { user: { loggedIn: false }},
  reducers: {
    userLogin(state, action) {
      state.user = { loggedIn: true, ...action.payload };
    },
    userLogout(state) {
      state.user = { loggedIn: false };
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice;

import React from "react";
import classes from "./CurrentAuctionCard.module.scss";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const CurrentAuctionCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
      <div className={[`${classes.current_auction} ${classes.red}`]}>
          <div className={classes.image_container}>
              <img className={classes.img} src={props.image.url} alt={props.image.alt}/>
          </div>
          <div
              className={classes.auction_info_container}
              style={{color: "white"}}
          >
              <div style={{display: "flex", flexDirection: "column"}}>
                  <span className={classes.auction_name}>{props.name}</span>
                  <span className={classes.info_text} style={{fontSize: 20}}>
            müzayede no: {props.muzayede_no}
          </span>
                  {/* <span className={classes.info_text} style={{ fontSize: 18 }}>
            ONLINE
          </span> */}
              </div>
          </div>
          <div className={classes.auction_date_container}>
              <div style={{display: "flex", flexDirection: "column"}}>
              <span className={classes.info_text} style={{fontSize: 18}}>
                  <span style={{color: 'red'}}>
                    {(new Date(props.start_date).toLocaleDateString('tr-TR', {
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric'
                    })).toLowerCase()}
                  </span>
                  <span style={{color: 'white'}}> I </span>
                  <span style={{color: 'white'}}>
                    {new Date(props.start_date).toLocaleTimeString('tr-TR', {
                        hour: '2-digit',
                        minute: '2-digit'
                    }).replace(':', '.')}
                  </span>
              </span>
                  <span className={classes.info_text} style={{fontSize: 18}}>
                  <span style={{color: 'red'}}>
                    {(new Date(props.end_date).toLocaleDateString('tr-TR', {
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric'
                    })).toLowerCase()}
                  </span>
                  <span style={{color: 'white'}}> I </span>
                  <span style={{color: 'white'}}>
                    {new Date(props.end_date).toLocaleTimeString('tr-TR', {
                        hour: '2-digit',
                        minute: '2-digit'
                    }).replace(':', '.')}
                  </span>
              </span>
              </div>
          </div>
          <div className={classes.btn_container}>
              <button
                  className={classes.current_auction__btn}
                  onClick={() => {
                      navigate(`/auctions/auction/${props.id}/${props.name}`);
                  }}
                  // style={{
                  //   backgroundColor: "white",
                  //   color: "black",
                  //   border: "2px solid black",
                  // }}
              >
                  eserleri gör
              </button>
          </div>
      </div>
  );
};

export default CurrentAuctionCard;

import React, { useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import classes from "./BlogDetail.module.scss";
import {get_request} from "../utils/ApiHandlers";
import {useSelector} from "react-redux";
import {marked} from "marked";
import Oval from "react-loading-icons/dist/esm/components/oval";
import ScrollTop from "../utils/ScrollTop";

const BlogDetail = (props) => {
    ScrollTop()
    props.changePage("blog")

    const { blogId } = useParams();
    const [blog, setBlog] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const user = useSelector((state) => state.user.user);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBlog = async () => {
            const response = await get_request(navigate, `/api/v1/blogs/${blogId}`, user)
            if (response.status === 200) {
                setBlog(response.data);
                setIsLoading(false);
            }
        }
        fetchBlog()
    }, []);

    const getMarkdownHtml = (markdown) => {
        return marked(markdown, { sanitize: true });
    };

    return (
        <div className={classes.blogDetailContainer}>
            {isLoading && (
                <div className={classes.spin_container}>
                    <Oval stroke="black" />
                </div>
            )}
            {blog && (
                <>
                    <div className={classes.blogContent}>
                        <h1 className={classes.blogTitle}>{blog.name}</h1>
                        <div className={classes.blogImage}>
                        <img
                            src={blog.coverImage.url}
                            alt={blog.coverImage.alt}
                            className={classes.blogImage} />
                        </div>
                        <span className={classes.blogText}>
                    {blog.content && (
                        <div cla
                            dangerouslySetInnerHTML={{
                                __html: getMarkdownHtml(blog.content),
                            }}
                        />
                    )}
                </span>
                        <div className={classes.blogMeta}>
                            <p className={classes.blogDateAuthor}>{new Date(blog.createDate).toLocaleDateString()} - upArt</p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default BlogDetail;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import store from "./store/redux";
import { Provider } from "react-redux";
import Footer from "./components/footer/Footer";
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
                <Footer />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);


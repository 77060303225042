export function formatPrice(value) {
  // Numeric değeri ters çevir
  const reversedValue = value.split("").reverse().join("");

  // Üç hanelik gruplara ayır
  const groupedValue = reversedValue.match(/.{1,3}/g);

  // Grupları ters çevir ve noktalarla birleştir
  const formattedValue = groupedValue.join(".").split("").reverse().join("");

  return formattedValue;
}

import React, { useEffect, useState } from "react";
import classes from "./ProductInfoContainer.module.scss";
import Timer from "../auctions/Timer";
import { get_request, post_request } from "../../utils/ApiHandlers";
import { useSelector } from "react-redux";
import toastr from "toastr";
import {marked} from "marked";
import ConfirmationModal from "../modals/Comfirmation";
import {useNavigate} from "react-router-dom";
import {formatPrice} from "../../utils/Formatters";

const ProductInfoContainer = (props) => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const getMarkdownHtml = (markdown) => {
        return marked(markdown, { sanitize: true });
    };

    const handlePurchaseClick = () => {
        setIsModalVisible(true);
    };

    const handleConfirmPurchase = async () => {
        setIsModalVisible(false);
        const response = await post_request(navigate, `/api/v1/products/buy/${props.id}` ,{})
        if (response.status === 200) {
            props.state = "SOLD"
            toastr.success("Sizinle en kısa zamanda iletişime geçeceğiz :)");
        }
    }

    const handleCancelPurchase = () => {
        setIsModalVisible(false);
    }

    return (
        <div className={classes.top_section__info}>

      <span className={classes.header_text} style={{ fontSize: 20 }}>
        <b>{props.name ? props.name : `'untitled'`}</b>
      </span>
            <span className={classes.header_text}>{props.artistFullName}</span>

            <span className={classes.desc}> {props.description && (
                <div dangerouslySetInnerHTML={{ __html: getMarkdownHtml(props.description) }}  />
                )} </span>
            <div className={classes.price_button_container}>
                <div className={classes.product_card__btn_container}>
                    {props.state === "ON_SALE" ? (
                        user.token ?
                            (
                                <>
                                <button onClick={handlePurchaseClick} className={classes.product_card__btn_container__btn}>
                                    satın al
                                </button>
                                </>
                            ) : (
                                <>
                                    <button
                                        className={classes.product_card__btn_container__btn}
                                    onClick={() => navigate("/login")}>
                                        satın almak için giriş yap
                                    </button>
                                </>
                            )
                    ) : (
                        <div className={classes.banner}>satıldı</div>
                    )}
                </div>
                <div className={classes.price_container}>
                <span className={classes.price}>
                  <b>{props.price} TL</b>
                </span>
                        <span id="2" style={{ color: "gray" }}>
                  KDV dahil: {props.price * 1.2} TL
                </span>
                </div>
            </div>
            <ConfirmationModal
                isVisible={isModalVisible}
                onConfirm={handleConfirmPurchase}
                onCancel={handleCancelPurchase}
                message={`"${props.name}" adlı eseri KDV dahil ${formatPrice((props.price * 1.2).toString())} TL karşılığında satın almayı onaylıyor musunuz?`}
            />
        </div>

    );
};

export default ProductInfoContainer;

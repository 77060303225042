import React from "react";
import classes from "./ArtistCard.module.scss";
import { useNavigate } from "react-router-dom";
const ArtistCard = (props) => {
  const nameParts = props.fullName.split(" ");
  let name;
  let surname;
  if (nameParts.length > 2) {
    name = nameParts.slice(0, 2).join(" ");
    surname = nameParts.slice(2);
  } else {
    name = nameParts[0];
    surname = nameParts[1];
  }


  const navigate = useNavigate();
  return (
    <div
      className={classes.artist_card}
      onClick={() => {
        navigate(`/artists/${props.id}`);
      }}
    >
      <img className={classes.artist_card__img} src={props.imageUrl} />

      <span className={classes.artist_card_name}>
        {name} <br />
        {surname}
      </span>
    </div>
  );
};

export default ArtistCard;

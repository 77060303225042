export function format_datetime(timestamp) {
  const date = new Date(timestamp); //
  const day =
    date.getDate() > 9 ? date.getDate() : ("0" + date.getDate()).toString();
  const month =
    date.getMonth() + 1 > 9
      ? (date.getMonth() + 1).toString()
      : "0" + (date.getMonth() + 1).toString();
  const year = date.getFullYear();

  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);

  const formattedDateTime = `${day}.${month}.${year} - ${hours}:${minutes}:${seconds}`;

  return formattedDateTime;
}

import React, { useEffect, useState } from "react";
import classes from "./AuctionProductDetails.module.scss";
import ImageContainer from "../components/product_details/ImageContainer";
import { useNavigate, useParams } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { get_request } from "../utils/ApiHandlers";
import { useSubscription } from "react-stomp-hooks";
import { format_datetime } from "../utils/DateHandlers";
import ImageFullScreen from "../components/modals/ImageFullScreen";
import AuctionProductInfoContainer from "../components/product_details/AuctionProductInfoContainer";
import { Oval } from "react-loading-icons";
import {uiActions} from "../store/uiSlice";

const AuctionProductDetails = (props) => {
  props.changePage("auctions")

  const navigate = useNavigate();

  const { product_id } = useParams();
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [load, setLoad] = useState(false);
  const [bidList, setBidList] = useState([]);
  const [fullImageUrls, setFullImageUrls] = useState([]);
  const [imageFullScreenVisible, setImageFullScreenVisible] = useState(false);

  const closeImageFullScreen = () => {
    setImageFullScreenVisible(false);
  };

  const openImageFullScreen = (imageUrls) => {
    setFullImageUrls(imageUrls);
    setImageFullScreenVisible(true);
  };


  useEffect(() => {
    const fetchProductDetails = async () => {
      const response = await get_request(
        navigate,
        `/api/v1/lots/${product_id}`,
        user
      );
      if (response.status === 200) {
        setProduct(response.data);
        setIsLoading(false);
      }
    };
    fetchProductDetails();
  }, [load]);

  useSubscription("/bids", (message) => {
    const messageBody = JSON.parse(message.body);
    if (product_id === messageBody.lotId) {
      setProduct((prevState) => {
        let new_product = prevState;
        new_product.currentPrice = messageBody.lotPrice;
        new_product.isWinner =
          new_product.isBidPlaced && messageBody.userId === user.user.id;
        return new_product;
      });
      setLoad((prev) => {
        return !prev;
      });
    }
  });

  return (
    <div
      className={[
        `${classes.product_details} ${
          props.currentPage ? classes.in : classes.out
        }`,
      ]}
    >
      {imageFullScreenVisible && (
        <ImageFullScreen
          imageUrls={fullImageUrls}
          onClose={closeImageFullScreen}
        />
      )}

      {isLoading ? ( // Show the loading spinner if data is still loading
        <div className={classes.spin_container}>
          <Oval stroke="black" />
        </div>
      ) : (
        <>
          <section className={classes.top_section}>
            <ImageContainer
              openImageFullScreen={openImageFullScreen}
              images={product && [product.coverImage].concat(product.assets)}
            />
            <AuctionProductInfoContainer
              endTime={product && product.saleEndTime}
              productId={product_id}
              currentPrice={product && product.currentPrice}
              lotNumber={product && product.lotNumber}
              productName={product && product.name}
              productDescription={product && product.description}
              artistFullName={product && product.artistInfo.fullName}
              isBidded={product && product.isBidPlaced}
              isOwned={product && product.isWinner}
              bidCount={product && product.bidCount}
              userActiveOffer={product && product.userActiveOffer}
              load={load}
            />
          </section>
          <section className={classes.artist_info}>
            <div className={classes.artist_info_left} onClick={() => {
              navigate(`/artists/${product.artistInfo.id}`)
            }}>
              <img
                className={classes.artist_photo}
                src={
                  product &&
                  product.artistInfo.profileImage &&
                  product.artistInfo.profileImage.url
                }
                alt={product && product.artistInfo.fullName}
              />
              <b className={classes.artist_name}>
                {product && product.artistInfo.fullName}
              </b>
            </div>
            <div className={classes.artist_info_right}>
              <div>{product && product.artistInfo.summary}</div>
            </div>
          </section>
          <section className={classes.bid_list}>
            <b className={classes.bids_title}>teklifler</b>
            <div className={classes.bid_table}>
              <div className={classes.bid_header}>
                <span className={classes.bid_item_text}>zaman</span>
                <span className={classes.bid_item_text}>teklif veren</span>
                <span className={classes.bid_item_text}>fiyat</span>
              </div>
              {product &&
                product.givenBids &&
                product.givenBids
                  .map((item) => {
                    return (
                      <div
                        key={Math.random()}
                        style={{
                          backgroundColor:
                            `${user.user.name} ${user.user.surname}` ===
                              item.userFullName && "#e2e2e2d8",
                        }}
                        className={classes.bid_item}
                      >
                        <span className={classes.bid_item_text}>
                          {format_datetime(item.time)}
                        </span>
                        <span className={classes.bid_item_text}>
                          {`${user.user.name} ${user.user.surname}` ===
                          item.userFullName
                            ? "Sizin teklifiniz"
                            : "*****"}
                        </span>
                        <span className={classes.bid_item_text}>
                          {item.price} TL
                        </span>
                      </div>
                    );
                  })
                  .reverse()}
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default AuctionProductDetails;

import React, { useRef, useState } from "react";
import classes from "./Signup.module.scss";
import { useDispatch } from "react-redux";
import { uiActions } from "../store/uiSlice";
import { api } from "../api/axios";
import { useNavigate } from "react-router-dom";
import toastr from "toastr";
import PhoneInput from "react-phone-number-input/input";
import CustomInput from "../components/phone_number/CustomInput";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {post_request} from "../utils/ApiHandlers";
import ScrollTop from "../utils/ScrollTop"; // İkonlar için

const Signup = (props) => {
  ScrollTop()
  props.changePage("signup")

  const navigate = useNavigate();

  const nameRef = useRef();
  const surnameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const phoneRef = useRef();
  const addressRef = useRef();
  const birthDateRef = useRef();
  const idNumberRef = useRef();

  const [passwordVisible, setPasswordVisible] = useState(false); // Şifre görünürlüğü için state

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const signupHandler = (e) => {
    e.preventDefault();
    post_request(
            navigate,
            "/api/v1/users",
            {
              name: nameRef.current.value,
              surname: surnameRef.current.value,
              email: emailRef.current.value,
              phone: phoneRef.current.value,
              address: addressRef.current.value,
              birthDate: birthDateRef.current.value,
              idNumber: idNumberRef.current.value * 1,
              password: passwordRef.current.value,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }
        )
        .then((response) => {
          if (response.status == 200) {
            navigate(`/sendmail/${emailRef.current.value}` );
            toastr.success("Kayıt başarılı.");
          }
        }
        );
  };

  return (
      <div
          className={[
            `${classes.signup} ${props.currentPage ? classes.in : classes.out}`,
          ]}
      >
        <form className={classes.signup__form} onSubmit={signupHandler}>
          <span className={classes.signup__form__title}>yeni üye</span>
          <input
              className={classes.signup__form__input}
              type="text"
              placeholder="ad"
              ref={nameRef}
              required
          ></input>
          <input
              className={classes.signup__form__input}
              type="text"
              placeholder="soyad"
              ref={surnameRef}
              required
          ></input>
          <input
              className={classes.signup__form__input}
              type="text"
              placeholder="e-posta"
              ref={emailRef}
              required
          ></input>
          <div className={classes.signup__form__password_container}>
            <input
                className={classes.signup__form__input}
                type={passwordVisible ? "text" : "password"}
                placeholder="şifre"
                ref={passwordRef}
                required
            />
            <span
                className={classes.signup__form__password_toggle}
                onClick={togglePasswordVisibility}
            >
            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
          </span>
          </div>
          <PhoneInput
              withCountryCallingCode={true}
              country="TR"
              value={"0"}
              inputComponent={CustomInput}
              onChange={(e) => {
                return e;
              }}
              ref={phoneRef}
          />

          <input
              className={classes.signup__form__input}
              type="text"
              placeholder="adres"
              ref={addressRef}
              required
          ></input>
          <input
              className={[`${classes.signup__form__input} ${classes.date_input}`]}
              type="date"
              placeholder="doğum tarihi"
              defaultValue="2001-01-01"
              ref={birthDateRef}
              onChange={(val) => console.log(val.currentTarget.value)}
              required
          ></input>
          <input
              className={classes.signup__form__input}
              type="text"
              placeholder="TC kimlik no"
              ref={idNumberRef}
              required
              maxLength={11}
              minLength={11}
          ></input>
          <input
              className={[
                `${classes.signup__form__input} ${classes.signup__form__btn}`,
              ]}
              type="submit"
              placeholder="üye ol"
              value="üye ol"
          ></input>
          <span className={classes.span}>
          Zaten bir hesabınız var mı? &nbsp;
            <a
                onClick={() => {
                  props.changePage("login", "/");
                  navigate("/login");
                }}
                className={classes.signup__form__login}
            >
            giriş yapın
          </a>
        </span>
        </form>
      </div>
  );
};

export default Signup;

import React, { useState } from "react";
import classes from "./BlogCard.module.scss";
import { useNavigate } from "react-router-dom";
import { uiActions } from "../../store/uiSlice";
import { useDispatch } from "react-redux";

const BlogCard = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState(false);
    const maxLength = 500;

    const changePage = (page) => {
        dispatch(uiActions.changePage(page));
    };

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    const parseHTMLToText = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const fullText = parseHTMLToText(props.content);

    const slicedText = fullText.length > maxLength && !isExpanded
        ? fullText.slice(0, maxLength) + "..."
        : fullText;

    const displayContent = fullText.length > maxLength && !isExpanded
        ? props.content.slice(0, props.content.indexOf(slicedText) + slicedText.length)
        : props.content;

    return (
        <div className={classes.blogItem}>
            <div onClick={() => {
                changePage('blog');
                navigate(`/blog/${props.id}`)
            }} className={classes.blogLink}>
                <div className={classes.blogImage}>
                    <img src={props.coverImage.url} alt={props.coverImage.alt} />
                    <div className={classes.blogTitle}>{props.name}</div>
                </div>
                <div className={classes.blogMeta}>
                    <p className={classes.blogDateAuthor}>{props.date} - upArt</p>
                    <div className={classes.redLine}></div>
                </div>
                <div className={classes.blogContent}>
                    <p>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: displayContent,
                            }}
                        />
                        {fullText.length > maxLength && (
                            <span
                                className={classes.readMore}
                                onClick={toggleReadMore}
                            >
                                 devamını oku
                            </span>
                        )}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default BlogCard;

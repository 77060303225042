import Select from 'react-select';

const ArtistSelect = ({ artists, handleArtistChange, classes }) => {
    const options = artists.map(artist => ({
        value: artist.artistId || artist.id,
        label: `${artist.artistFullName || artist.fullName} (${artist.lotCount || artist.productCount || 0})`
    }));

    const customStyles = {
        control: (baseStyles) => ({
            ...baseStyles,
            border: '2px solid #ededed9d',
            borderRadius: '20px',
            transition: '300ms',
            boxShadow: 'none',
            minHeight: 'auto',
            margin: 0,
            height: '100%',
            '&:hover': {
                border:'2px solid #ededed9d',
            },
            padding: '2px 2px 2px 10px',
            textAlign: 'left'
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            fontSize: '0.8rem',
            fontFamily: 'Gilroy-Light',
            color: 'black',
        }),
        menu: (baseStyles) => ({
            ...baseStyles,
            width: '94%',
            margin: '2px 0 0 0 ',
            left: '3%',
            borderRadius: '10px', // menu'nün kendisine de ekliyoruz
            overflow: 'hidden',
            textAlign: 'left',
            fontSize: '0.8rem',
            fontFamily: 'Gilroy-Light',
        }),
        menuList: (baseStyles) => ({
            ...baseStyles,
            padding: '0',
            borderRadius: '10px',
            '&::-webkit-scrollbar': {
                width: '6px',  // scrollbar genişliği
            },
            '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',  // scrollbar arka plan rengi
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#888',  // scrollbar rengi
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                background: '#555',  // scrollbar hover rengi
            },
        }),
        container: (baseStyles) => ({
            ...baseStyles,
            width: '100%',
        }),
        valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: '0',
        }),
        input: (baseStyles) => ({
            ...baseStyles,
            fontSize: '0.8rem',
            fontFamily: 'Gilroy-Light',
            margin: '0',
            padding: '0',
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            fontSize: '0.8rem',
            fontFamily: 'Gilroy-Light',
            margin: '0',
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: '0.8rem',
            fontFamily: 'Gilroy-Light',
            padding: '8px',
            backgroundColor: state.isSelected ? '#f0f0f0' : 'white',
            color: 'black',
            '&:hover': {
                backgroundColor: '#f5f5f5',
            },
        }),
    };

    return (
        <Select
            options={options}
            onChange={handleArtistChange}
            defaultValue={null}
            isClearable={true}
            isSearchable={true}
            placeholder="sanatçı seç"
            classNamePrefix="select"
            className={classes}
            styles={customStyles}
            menuPosition="fixed"
            menuPlacement="auto"
            noOptionsMessage={() => "sonuç bulunamadı"}
        />
    );
};

export default ArtistSelect;
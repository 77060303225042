import React, { useEffect, useState } from "react";
import classes from "./Auctions.module.scss";
import resim from "../assets/resim.jpg";
import CurrentAuctionCard from "../components/auctions/CurrentAuctionCard";
import PastAuctionCard from "../components/auctions/PastAuctionCard";
import Footer from "../components/footer/Footer";
import { get_request } from "../utils/ApiHandlers";
import { format_datetime } from "../utils/DateHandlers";
import { useSelector } from "react-redux";
import { Oval } from "react-loading-icons";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";
import ScrollTop from "../utils/ScrollTop";

const Auctions = (props) => {
  ScrollTop()
  props.changePage("auctions")

  const navigate = useNavigate();
  const [currentAuction, setCurrentAuction] = useState(null);
  const [pastAuctions, setPastAuctions] = useState(null);

  useEffect(() => {
    const fetchAuctions = async () => {
      const current_auction_response = await get_request(
        navigate,
        `/api/v1/auctions/current`
      );
      if (current_auction_response.data) {
        setCurrentAuction(current_auction_response.data);
      }

      const past_auction_response = await get_request(
        navigate,
        `/api/v1/auctions/pasts`
      );
      if (past_auction_response.data) {
        setPastAuctions(past_auction_response.data.content);
      }
    };

    fetchAuctions();
  }, []);

  return (
    <div
      className={[
        `${classes.auctions} ${props.currentPage ? classes.in : classes.out}`,
      ]}
    >
      <div className={classes.current_auctions_container}>
        <span
          style={{
            fontSize: 24,
            color: "black",
            marginBottom: 8,
            fontFamily: "Gilroy-SemiBold",
          }}
        >
          güncel müzayedeler
        </span>
        {currentAuction ? (
          <CurrentAuctionCard
            image={currentAuction.coverAsset}
            id={currentAuction.id}
            muzayede_no={
              currentAuction.auctionNo ? currentAuction.auctionNo : 1
            }
            name={currentAuction.name}
            start_date={
              currentAuction.startTime &&
              currentAuction.startTime
            }
            end_date={
              currentAuction.endTime && currentAuction.endTime
            }
          />
        ) : (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1rem",
              color: "gray",
              height: "100px",
              fontFamily: "Gilroy-Light",
            }}
          >
            aktif müzayede bulunmamaktadır
          </span>
        )}
      </div>

      <section className={classes.past_auctions_container}>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 18,
            color: "black",
            marginBottom: 8,
            fontFamily: "Gilroy-Light",
          }}
        >
          geçmiş müzayedeler
        </span>
        {pastAuctions ? (
          pastAuctions.map((pastAuction) => {
            return (
              <PastAuctionCard
                image={pastAuction.coverAsset}
                id={pastAuction.id}
                muzayede_no={pastAuction.auctionNo ? pastAuction.auctionNo : 1}
                name={pastAuction.name}
                start_date={
                  pastAuction.startTime &&
                  pastAuction.startTime
                }
                end_date={
                  pastAuction.endTime && pastAuction.endTime
                }
              />
            );
          })
        ) : (
          <Oval className={classes.spin} stroke="black" />
        )}
      </section>
    </div>
  );
};

export default Auctions;

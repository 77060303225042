import React, { useEffect, useState } from "react";
import classes from "./AuctionProductDetails.module.scss";
import ImageContainer from "../components/product_details/ImageContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { get_request } from "../utils/ApiHandlers";
import ImageFullScreen from "../components/modals/ImageFullScreen";
import ProductInfoContainer from "../components/product_details/ProductInfoContainer";
import Oval from "react-loading-icons/dist/esm/components/oval";
import { marked } from "marked";
import scrollTop from "../utils/ScrollTop";

const ProductDetails = (props) => {
  scrollTop()
  props.changePage("products")

  const navigate = useNavigate();
  const { product_id } = useParams();

  const [product, setProduct] = useState(null);
  const [artist, setArtist] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fullImageUrls, setFullImageUrls] = useState([]);
  const [imageFullScreenVisible, setImageFullScreenVisible] = useState(false);

  const closeImageFullScreen = () => {
    setImageFullScreenVisible(false);
  };

  const openImageFullScreen = (imageUrls) => {
    setFullImageUrls(imageUrls);
    setImageFullScreenVisible(true);
  };

  const getMarkdownHtml = (markdown) => {
    return marked(markdown, { sanitize: true });
  };

  useEffect(() => {
    const fetchProductDetails = async () => {
      const response = await get_request(
        navigate,
        `/api/v1/products/${product_id}`
      );
      if (response.status === 200) {
        setProduct(response.data);
        setImageUrls(
          [response.data.coverImage].concat(response.data.mediaAssets)
        );
        const artistResponse = await get_request(
          navigate,
          `/api/v1/artists/${response.data.artistId}`
        );
        if (artistResponse.status === 200) {
          setArtist(artistResponse.data);
        }
      }
      setIsLoading(false);
    };
    fetchProductDetails();
  }, [product_id, navigate]);

  return (
    <div
      className={`${classes.product_details} ${
        props.currentPage ? classes.in : classes.out
      }`}
    >
      {isLoading ? (
        <div className={classes.spin_container}>
          <Oval stroke="black" />
        </div>
      ) : (
        <>
          {imageFullScreenVisible && (
            <ImageFullScreen
              imageUrls={fullImageUrls}
              onClose={closeImageFullScreen}
            />
          )}
          <section className={classes.top_section}>
            <ImageContainer
              openImageFullScreen={openImageFullScreen}
              images={imageUrls}
            />
            <ProductInfoContainer
              productId={product_id}
              price={product?.price}
              name={product?.name}
              description={product?.description}
              artistFullName={artist?.fullName}
              state={product?.state}
            />
          </section>
          <section className={classes.artist_info}>
            <div className={classes.artist_info_left} onClick={() => navigate(`/artists/${product.artistId}`)}>
              <img
                className={classes.artist_photo}
                src={artist?.profileImage?.url}
                alt={artist?.fullName}
              />
              <b className={classes.artist_name}>{artist?.fullName}</b>
            </div>
            <div className={classes.artist_info_right}>
              <div
                dangerouslySetInnerHTML={{
                  __html: getMarkdownHtml(artist?.summary),
                }}
              ></div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default ProductDetails;

import React, { useState, useEffect } from 'react';
import classes from './Homepage.module.scss';
import { get_request } from "../utils/ApiHandlers";
import { useNavigate } from "react-router-dom";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Oval from "react-loading-icons/dist/esm/components/oval";
import HomepageTimer from "../components/homepage/HomepageTimer";
import {uiActions} from "../store/uiSlice";
import {useDispatch} from "react-redux";
import BlogCard from "../components/blog/BlogCard";
import ScrollTop from "../utils/ScrollTop";

const Homepage = (props) => {
    ScrollTop()
    props.changePage("homepage")

    const [auction, setAuction] = useState();
    const [auctionType, setAuctionType] = useState();
    const [storeItems, setStoreItems] = useState([]);
    const [blogItems, setBlogItems] = useState([]);
    const [isLoadingAuction, setIsLoadingAuction] = useState(true);
    const [isLoadingProduct, setIsLoadingProduct] = useState(true);
    const [isLoadingBlog, setIsLoadingBlog] = useState(true);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const storeSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ],
    };

    const blogSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ],
    };


    function formatDateToTurkish(dateString) {
        const date = new Date(dateString);

        const options = {
            day: '2-digit',
            month: 'long',
            hour: '2-digit',
            minute: '2-digit',
            timeZone: 'Europe/Istanbul', // UTC+3
            hour12: false,
        };

        let formattedDate = date.toLocaleDateString('tr-TR', options);
        const indexToReplace = formattedDate.length - 6; // Adjust the index according to your string structure
        formattedDate = formattedDate.substring(0, indexToReplace) + ', ' + formattedDate.substring(indexToReplace + 1);
        return `${formattedDate.replace(/\:/g, '.')} UTC+3`.toLowerCase();
    }

    useEffect(() => {
        const fetchAuction = () => {
             get_request(navigate, '/api/v1/auctions/current').then( response => {
                if (!response.data) {
                     get_request(navigate, '/api/v1/auctions/incoming?size=1').then( response => {
                        if (response.data.content.length === 0) {
                             get_request(navigate, '/api/v1/auctions/pasts?size=1').then(response => {
                                if (response.data.content.length > 0) {
                                    setAuction(response.data.content[0]);
                                    setAuctionType('past');
                                }
                            })
                        } else {
                            setAuction(response.data.content[0]);
                            setAuctionType('incoming');
                        }
                    })
                } else {
                    setAuction(response.data);
                    setAuctionType('current');
                }
            })
        };
        setIsLoadingAuction(false)
        const fetchStoreItems = async () => {
            let size = window.innerWidth < 700 ? 6 : 12;
            const response = await get_request(navigate, `/api/v1/products?size=${size}&sort=createDate,desc`);
            setStoreItems(response.data.content);
            setIsLoadingProduct(false)
        };

        const fetchBlogs = async () => {
            const response = await get_request(navigate, `/api/v1/blogs?sort=createDate,desc`)
            if (response.status === 200) {
                setBlogItems(response.data.content);
                setIsLoadingBlog(false)
            }
        }


        fetchAuction();
        fetchStoreItems();
        fetchBlogs();
    }, []);

    return (
        <div className={classes.homepage}>
            <section style={{marginTop: "20px"}} className={classes.auctionSection}>
                {isLoadingAuction && (
                    <div className={classes.spin_container}>
                        <Oval stroke="black" />
                    </div>
                )}
                {auction && (
                    <>
                        <div className={classes.imageSection}>
                            <img src={auction.coverAsset.url} alt={auction.coverAsset.alt || "görsel"}
                                 className={classes.auctionImage}
                                 onClick={() => {
                                     navigate(`/auctions/auction/${auction.id}/${auction.name}`)
                                    props.changePage("auctions")
                                 }}
                            />
                        </div>
                        <div className={classes.textSection}>
                            <div className={classes.container}>
                            {auctionType === "current" && <div className={classes.liveLabel}>canlı</div>}
                            {auctionType === "incoming" && <div className={classes.liveLabel}>yakında</div>}
                            <h3 className={classes.title}>{auction.auctionNo ? `${auction.auctionNo}. ` : ""}online müzayede</h3>
                            <p className={classes.date}>{formatDateToTurkish(auction.startTime)}</p>
                            </div>
                            <div className={classes.container}><h2 className={classes.auctionName}>{auction.name}</h2></div>
                            {auctionType === "current" && <div className={classes.container}><HomepageTimer expiryTimestamp={auction.endTime} /></div>}
                            {auctionType === "incoming" && <div className={classes.container}><HomepageTimer expiryTimestamp={auction.startTime} /></div>}
                            {auctionType === "past" && <div className={classes.container}><p className={classes.date}>müzayede sona erdi</p></div>}
                        </div>

                    </>
                )}
            </section>

            <section className={classes.storeSection}>
                {isLoadingProduct && (
                    <div className={classes.spin_container}>
                        <Oval stroke="black" />
                    </div>
                )}
                <h2 className={classes.sectionTitle}>mağaza</h2>
                <Slider {...storeSettings} className={classes.carousel}>
                    {storeItems.map(item => (
                        <div className={classes.carouselInner} key={item.id}>
                            <div className={classes.carouselItem}>
                                <img
                                    src={item.coverImage.url}
                                    alt={item.coverImage.alt}
                                    className={classes.storeImage}
                                    onClick={() => {
                                        props.changePage("products")
                                        navigate(`/hemen-al/${item.id}`)
                                    }
                                }
                                />
                                <p className={classes.artworkTitle}>{item.name}</p>
                                <p className={classes.artistName}>{item.artistName}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </section>

            <section className={classes.blogSection}>
                {isLoadingBlog && (
                    <div className={classes.spin_container}>
                        <Oval stroke="black" />
                    </div>
                )}
                <h2 className={classes.sectionTitle}>blog</h2>
                <Slider {...blogSettings} className={classes.carousel}>
                    {blogItems.map(item => (
                        <div className={classes.carouselInner} key={item.id}>
                            <div className={classes.carouselItem}>
                                <BlogCard
                                    key={item.id}
                                    id={item.id}
                                    name={item.name}
                                    coverImage={item.coverImage}
                                    date={new Date(item.createDate).toLocaleDateString()}
                                    content={item.content}
                                />
                            </div>
                        </div>
                    ))}
                </Slider>
            </section>
        </div>
    );
};

export default Homepage;

import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "./store/uiSlice";
import Navbar from "./components/nav/Navbar";
import Homepage from "./pages/Homepage";
import Signup from "./pages/Signup";
import Artists from "./pages/Artists";
import Login from "./pages/Login";
import Auctions from "./pages/Auctions";
import AuctionDetails from "./pages/AuctionDetails";
import AuctionProductDetails from "./pages/AuctionProductDetails";
import {Route, Routes, useLocation} from "react-router-dom";
import Verification from "./pages/Verification";
import SendEmail from "./pages/SendEmail";
import { StompSessionProvider } from "react-stomp-hooks";
import ArtistDetails from "./pages/ArtistDetails";
import Products from "./pages/Products";
import ProductDetails from "./pages/ProductDetails";
import Account from "./pages/Account";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail";

const App = () => {
  const page = useSelector((state) => state.ui.page);

  const dispatch = useDispatch();
  const location = useLocation();

  const changePage = (page, previous = location.pathname, keepPrevious = false) => {
    dispatch(uiActions.changePage(page, previous, keepPrevious));
  };

  return (
    <div>
      <StompSessionProvider
        url={"wss://backend.upart.art/ws"}
        //All options supported by @stomp/stompjs can be used here
      >
          <Navbar/>
          <Routes>
            <Route
              path="/"
              element={<Homepage changePage={changePage} currentPage={page === "homepage"} />}
            ></Route>
            <Route
              path="/login"
              element={
                <Login changePage={changePage} currentPage={page === "login"} />
              }
            ></Route>
            <Route
              path="/signup"
              element={<Signup changePage={changePage} currentPage={page === "signup"} />}
            ></Route>
            <Route
              path="/artists"
              element={<Artists changePage={changePage} currentPage={page === "artists"} />}
            ></Route>
            <Route
              path="/artists/:artist_id"
              element={<ArtistDetails changePage={changePage} currentPage={page === "artist"} />}
            ></Route>
            <Route
              path="/auctions" //auctions
              element={<Auctions changePage={changePage} currentPage={page === "auctions"} />}
            ></Route>
            <Route
              path="/auctions/auction/:auction_id/:auction_name"
              element={<AuctionDetails changePage={changePage} currentPage={page === "auction"} />}
            ></Route>
            <Route
              path="/auctions/auction/product_details/:product_id"
              element={<AuctionProductDetails changePage={changePage} currentPage={page === "auction"} />}
            ></Route>
            <Route
              path="/verify/:userId/:token"
              element={<Verification currentPage={page === "verify"} />}
            ></Route>
            <Route
              path="/sendmail/:email"
              element={<SendEmail currentPage={page === "sendmail"} />}
            ></Route>
            <Route
                path="/hemen-al"
                element={<Products changePage={changePage} currentPage={page === "products"} />}
            ></Route>
            <Route
                path="/hemen-al/:product_id"
                element={<ProductDetails changePage={changePage} currentPage={page === "products"} />}
            ></Route>
            <Route
                path={"/blog"}
                element={<Blog changePage={changePage} currentPage={page === "blog"} />}
            ></Route>
            <Route
              path={"/blog/:blogId"}
              element={<BlogDetail changePage={changePage} currentPage={page === "blog"} />}
              ></Route>
            <Route path="/hesabım" element={<Account changePage={changePage} currentPage={page === "account"} />}></Route>
          </Routes>
      </StompSessionProvider>
    </div>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import classes from "./Products.module.scss";
import { get_request } from "../utils/ApiHandlers";
import { useSelector } from "react-redux";
import ImageFullScreen from "../components/modals/ImageFullScreen";
import ProductCard from "../components/product/ProductCard";
import { useNavigate } from "react-router-dom";
import Oval from "react-loading-icons/dist/esm/components/oval";
import ScrollTop from "../utils/ScrollTop";
import ArtistSelect from "../components/select/ArtistSelect";
import GenericSelect from "../components/select/GenericSelect";

const Products = (props) => {
    ScrollTop()
    props.changePage("products")

    const navigate = useNavigate();

    const user = useSelector((state) => state.user.user);
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false); // New state for load more loading
    const [imageFullScreenVisible, setImageFullScreenVisible] = useState(false);
    const [fullImageUrls, setFullImageUrls] = useState([]);
    const [name, setName] = useState("");
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState("");
    const [hasMoreProducts, setHasMoreProducts] = useState(true);
    const [artists, setArtists] = useState([]);
    const [selection, setSelection] = useState("")

    const [executeFilter, setExecuteFilter] = useState(false);
    const [isFiltersChanged, setIsFiltersChanged] = useState(false);

    const [filters, setFilters] = useState(new Map());

    useEffect(() => {
        const fetchArtists = async () => {
            const response = await get_request(navigate, `/api/v1/products/artists`);
            if (response.status === 200) {
                setArtists(response.data);
            }
        };

        fetchArtists();
    }, []);

    useEffect(() => {
        const fetchProducts = async () => {
            page > 0 ? setIsLoadMoreLoading(true): setIsLoading(true);
            const response = await get_request(
                navigate,
                `/api/v1/products?page=${page}&size=12&sort=${sort}&filter=${generateFilter(filters)}`,
                user
            );
            if (response.status === 200) {
                const fetchedProducts = response.data.content;
                const updatedProducts = [...products, ...fetchedProducts]
                setProducts(updatedProducts);
                setHasMoreProducts(updatedProducts.length < response.data.page.totalElements);
            }
            page > 0 ? setIsLoadMoreLoading(false): setIsLoading(false);
        };

        fetchProducts();
    }, [page, sort, executeFilter]);


    function generateFilter(filters) {
        if (filters.size === 0) return "";
        return Array.from(filters.entries())
            .map(([key, value]) => `${key}==${value}`)
            .join(';');
    }

    const executeFilters = () => {
        setExecuteFilter(prev => !prev);
        setIsFiltersChanged(false);
        setPage(0);
        setProducts([]);
    }


    const closeImageFullScreen = () => {
        setImageFullScreenVisible(false);
    };

    const openImageFullScreen = (imageUrls, name) => {
        setFullImageUrls(imageUrls);
        setName(name);
        setImageFullScreenVisible(true);
    };

    const loadMore = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handleSortChange = (selectedOption) => {
        console.log(selectedOption);
       if (selectedOption) {
            setSort(selectedOption.value);
            setSelection(selectedOption.label + " için");
        } else {
            setSort("");
            setSelection("");
        }
        setPage(0);
        setProducts([]);
    };

    const handleArtistChange = (selectedOption) => {
        if (selectedOption) {
            setFilters((prev) => prev.set("artistId", selectedOption.value));
            setSelection(selectedOption.label.split(" ").slice(0, -1).join(" ") + " için");
        } else {
            setFilters((prev) => {
                prev.delete("artistId");
                return prev;
            });
            setSelection("")
        }

        executeFilters();
    };

    const handleStateChange = (selectedOption) => {
        if (selectedOption) {
            setFilters((prev) => prev.set("state", selectedOption.value));
        } else {
            setFilters((prev) => {
                prev.delete("state");
                return prev;
            })
        }
        executeFilters()
    }

    return (
        <div
            className={[
                `${classes.auction_details} ${
                    props.currentPage ? classes.in : classes.out
                }`,
            ]}
        >
            {imageFullScreenVisible && (
                <ImageFullScreen
                    imageUrls={fullImageUrls}
                    name={name}
                    onClose={closeImageFullScreen}
                />
            )}
            <div className={classes.products__header}>
                <div className={classes.products__header__info}>
                <span className={classes.products__header__text}>
                {selection} {products.length} sonuç listeleniyor
                </span>

                    <div className={classes.dropdown_container}>

                        <div className={classes.dropdown_inner_container}>
                            <GenericSelect
                                option={[
                                    { value: 'SOLD', label: 'satılan ürünler' },
                                    { value: 'ON_SALE', label: 'satıştaki ürünler' }
                                ]}
                                handleChange={handleStateChange}
                                classes={classes.sort_dropdown}
                                placeholder="tüm ürünler"
                            />
                        </div>
                        <div className={classes.dropdown_inner_container}>
                            <GenericSelect
                                option={[
                                    { value: 'createDate,desc', label: 'en yeni ürünler'},
                                    { value: 'createDate,asc', label: 'en eski ürünler'},
                                    { value: 'price,desc', label: 'fiyata göre azalan',},
                                    { value: 'price,asc', label: 'fiyata göre artan'},
                                    { value: 'name,asc', label: 'isme göre artan'},
                                    { value: 'name,desc', label: 'isme göre azalan'}
                                ]}
                                handleChange={handleSortChange}
                                classes={classes.sort_dropdown}
                                placeholder="sıralama seç"
                                />
                        </div>
                        <div className={classes.dropdown_inner_container} >
                            <ArtistSelect
                                artists={artists}
                                handleArtistChange={handleArtistChange}
                                classes={classes.artist_filter_dropdown}
                            />
                        </div>

                    </div>
                </div>
            </div>
            <div className={classes.auction_product_container}>
                {isLoading && (
                    <div className={classes.spin_container}>
                        <Oval stroke="black"/>
                    </div>
                )}

                {products.map((item) => (
                    <ProductCard
                        openImageFullScreen={openImageFullScreen}
                        imageUrls={[item.coverImage].concat(item.mediaAssets)}
                        key={item.id}
                        id={item.id}
                        artistName={item.artistName}
                        name={item.name}
                        price={item.price}
                        category={item.category}
                                description={item.description}
                                state={item.state}
                            />
                ))}

            </div>
            {products.length === 0 && !isLoading && (
                <span style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    fontFamily: 'Gilroy-Light',
                    fontSize: '0.9rem',
                    marginTop: '1.5rem',
                }}>
                    eser bulunamadı
                </span>
            )}
            <div className={classes.products__header}>
                {!isLoading && hasMoreProducts && (
                    isLoadMoreLoading ? (
                        <div className={classes.spin_container}>
                            <Oval stroke="black" />
                        </div>
                    ) : (
                        <button onClick={loadMore} className={classes.load_more_button}>
                            daha fazla yükle
                        </button>
                    )
                )}
            </div>
        </div>
    );
};

export default Products;

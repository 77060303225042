import React, { useState, useEffect } from 'react';
import { FaArrowLeft } from 'react-icons/fa'; // FA Icon import
import classes from './MyWinnings.module.scss';
import {get_request} from "../../utils/ApiHandlers";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {uiActions} from "../../store/uiSlice";

const MyWinnings = () => {
    const [auctions, setAuctions] = useState([]);
    const [selectedAuction, setSelectedAuction] = useState(null);
    const [auctionLots, setAuctionLots] = useState(null);
    const [selectedLot, setSelectedLot] = useState(null);
    const [selectedLotAssets, setSelectedLotAssets] = useState(null);
    const [selectedArtworkIndex, setSelectedArtworkIndex] = useState(0);

    const navigate = useNavigate()
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();

    const changePage = (page) => {
        dispatch(uiActions.changePage(page));
    };


    useEffect(() => {

        const fetchWinningAuctions = async () => {
            const response = await get_request(navigate, '/api/v1/users/winnings', user)
            if (response.status === 200) {
                setAuctions(response.data)
            }
        }

        fetchWinningAuctions()
    }, []);

    const fetchAuctionLots = async (auctionId) => {

        const response = await get_request(navigate, `/api/v1/users/winnings/${auctionId}`, user)
        if (response.status === 200) {
            setAuctionLots(response.data)
        }
    };

    const handleAuctionClick = (auction) => {
        setSelectedAuction(auction)
        fetchAuctionLots(auction.id);
    };

    const handleLotClick = (lot) => {
        if (lot.assets){
            setSelectedLotAssets([lot.coverImage, ...lot.assets])
        } else {
            setSelectedLotAssets([lot.coverImage])
        }
        setSelectedLot(lot);
    }

    const handleArtworkClick = (index) => {
        setSelectedArtworkIndex(index);
    };


    const handleBackToList = () => {
        setSelectedLot(null);
        setSelectedLotAssets(null);
        setSelectedArtworkIndex(0);
    };

    const handleBackToAuctions = () => {
        setSelectedAuction(null);
        setAuctionLots(null);
    };

    return (
        <div className={classes.myWinnings}>
            {!selectedAuction ? (
                <div className={classes.gridContainer}>
                    {auctions.map((auction) => (
                        <div key={auction.id} className={classes.artworkCard} onClick={() => handleAuctionClick(auction)}>
                            <img src={auction.coverAsset.url} alt={auction.coverAsset.alt} className={classes.artworkImage} />
                            <div className={classes.artworkInfo}>
                                <h3>{auction.name}</h3>
                                <p>müzayede no: {auction.auctionNo}</p>
                                <p>başlangıç: {new Date(auction.startTime).toLocaleString()}</p>
                                <p>bitiş: {new Date(auction.endTime).toLocaleString()}</p>
                            </div>
                        </div>
                    ))}
                </div>
            ) : selectedLot !== null ? (
                <>
                    <div className={classes.backButton} onClick={handleBackToList}>
                        <FaArrowLeft/>
                    </div>

                    <div className={classes.artworkDetail}>
                        <div className={classes.artworkImageContainer}>
                            {selectedArtworkIndex > 0 && (
                                <div className={classes.leftArrow}
                                     onClick={() => handleArtworkClick(selectedArtworkIndex - 1)}></div>
                            )}
                            <img
                                src={selectedLotAssets[selectedArtworkIndex].url}
                                alt={selectedLotAssets[selectedArtworkIndex].alt}
                                className={classes.artworkImage}
                            />
                            {selectedArtworkIndex < selectedLotAssets.length - 1 && (
                                <div className={classes.rightArrow}
                                     onClick={() => handleArtworkClick(selectedArtworkIndex + 1)}></div>
                            )}
                        </div>
                        <div className={classes.artworkInfoContainer}>
                            <div className={classes.artworkDate}>
                                <p>{new Date(selectedAuction.endTime).toLocaleDateString()}</p>
                                <span>tarihinden itibaren koleksiyonunuzun bir parçasıdır.</span>
                            </div>
                            <div className={classes.ownerInfo} onClick={() => {
                                changePage('artists');
                                navigate(`/artists/${selectedLot.artistInfo.id}`)
                            }}>
                                <img src={selectedLot.artistInfo.profileImage.url} alt={selectedLot.artistInfo.profileImage.alt}
                                     className={classes.ownerImage}/>
                                <div className={classes.ownerDetail}>
                                <h3>{selectedLot.artistName}</h3>
                                <div className={classes.underArtistName}>
                                    <p>
                                        {selectedLot.name}
                                    </p>
                                    <p>
                                        {selectedAuction.name}
                                    </p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.artworkDescription}>
                        <h5>eser açıklaması</h5>
                        <p>{selectedLot.description}</p>
                    </div>
                </>
            ) : (
                <>
                    <div className={classes.backButton} onClick={handleBackToAuctions}>
                        <FaArrowLeft/>
                    </div>

                <div className={classes.gridContainer}>

            {auctionLots && auctionLots.map((lot, index) => (
                        <div key={lot.id} className={classes.artworkCard} onClick={() => handleLotClick(lot)}>
                            <img src={lot.coverImage.url} alt={lot.coverImage.alt} className={classes.artworkImage} />
                            <div className={classes.artworkInfo}>
                                <h3>{lot.artistInfo.fullName}</h3>
                                <p>{lot.name}</p>
                                <p>{selectedAuction.name}</p>
                                <p>{new Date(selectedAuction.endTime).toLocaleDateString()}</p>
                            </div>
                        </div>
                    ))}
                </div>
                </>
            )}
        </div>
    );
};

export default MyWinnings;

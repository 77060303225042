import React, { useEffect, useState } from "react";
import classes from "./AuctionProductInfoContainer.module.scss";
import Timer from "../auctions/Timer";
import { get_request, post_request } from "../../utils/ApiHandlers";
import { useSelector } from "react-redux";
import toastr from "toastr";
import {useNavigate} from "react-router-dom";
import ConfirmationModal from "../modals/Comfirmation";

const AuctionProductInfoContainer = (props) => {
  const navigate = useNavigate();
  const [newBid, setNewBid] = useState(0);
  const [bidOptions, setBidOptions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const user = useSelector((state) => state.user.user);
  const giveBid = async () => {
    setIsModalVisible(false)
    const response = await post_request(navigate,"/api/v1/bids", {
      lotId: props.productId,
      price: newBid,
    }, user);

    if (response.status === 200 && response.data.isSuccess) {
      toastr.success(response.data.message);
    } else if (response.status === 200) {
      toastr.warning(response.data.message);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  }

  const handleClick = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    const priceElement = document.getElementById("3");
    if (priceElement) {
      priceElement.classList.add(classes.change);
    }

    setTimeout(() => {
      if (priceElement) {
        priceElement.classList.remove(classes.change);
      }
    }, 500);
  }, [props.load]);

  useEffect(() => {
    const fetchBidOptions = async () => {
      const response = await get_request(
          navigate,
        `/api/v1/bids/price-options/${props.productId}`,
          user
      );
      if (response.status === 200) {
        setBidOptions(response.data.prices);
        setNewBid(response.data.prices[0]);
      }
    };
    fetchBidOptions();
  }, [props.load]);
/*
  useSubscription("/bids", (message) => {
    const messageBody = JSON.parse(message.body);
    if (props.productId === messageBody.lotId) {
      props.currentPrice = messageBody.lotPrice;
        props.isWinner = props.isBidPlaced && messageBody.userId === user.user.id;
    }
  });
*/
  return (
    <div className={classes.top_section__info}>
      <span className={classes.header_text} style={{ fontSize: 20 }}>
        <b>{props.productName ? props.productName : "untitled"}</b>
      </span>
      <span className={classes.header_text}>lot: {props.lotNumber}</span>
      <span className={classes.header_text}>{props.artistFullName}</span>

      <span className={classes.desc}>
        {props.productDescription
          ? props.productDescription
          : "kağıt üzerine karışık, teknik, imzalı, 64x84"}
      </span>
      <div className={classes.price_container}>
        <span id="3" className={classes.price}>
          <b>{props.currentPrice} TL</b>
        </span>
        <span id="2" style={{ color: "gray" }}>
          KDV dahil: {props.currentPrice * 1.2} TL
        </span>
        <Timer
          isBidded={props.isBidded}
          isOwned={props.isOwned}
          expiryTimestamp={props.endTime}
          width="100%"
        />
        <span>teklifler: {props.bidCount}</span>
      </div>
      <div className={classes.bid_container}>
        <span className={classes.bid_text}>
          lütfen maksimum teklifinizi seçiniz
        </span>
        {props.userActiveOffer &&
        <span className={classes.bid_text}>
          güncel teklifiniz {props.userActiveOffer.price} TL
        </span>
        }
        <div className={classes.bid_input_container}>
          <div
            className={classes.bid_input}
            onMouseEnter={() => setDropdownOpen(true)}
            onMouseLeave={() => setDropdownOpen(false)}
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            {newBid ? newBid : bidOptions[0]} TL
          </div>
          <div
            className={classes.bid_options}
            style={{ display: dropdownOpen ? "flex" : "none" }}
            onMouseEnter={() => setDropdownOpen(true)}
            onMouseLeave={() => setDropdownOpen(false)}
          >
            {bidOptions.map((option) => {
              return (
                <div
                  onClick={() => {
                    setNewBid(option);
                    setDropdownOpen(false);
                  }}
                  className={classes.option}
                >
                  {option} TL
                </div>
              );
            })}
          </div>
        </div>
        <button onClick={handleClick} className={classes.bid_btn}>
          teklif ver
        </button>
      </div>
      <ConfirmationModal
          isVisible={isModalVisible}
          onConfirm={giveBid}
          onCancel={handleCancel}
          message={`"${props.productName ? props.productName : "untitled"}" adlı esere ${newBid} TL teklif vermeyi onaylıyor musunuz?`}
      />
    </div>
  );
};

export default AuctionProductInfoContainer;

import React from "react";
import classes from "./ArtworkCard.module.scss";
import { useNavigate } from "react-router-dom";

const ArtworkCard = (props) => {
  const navigate = useNavigate();
  return (
    <div
      className={classes.artwork_card}
      onClick={() => {
        console.log("btn is clicked");
      }}
    >
      <img className={classes.artwork_card__img} src={props.imageUrl} />
      {/* <span className={classes.artwork_card_name}>
        {name} <br />
        {surname}
      </span> */}
    </div>
  );
};

export default ArtworkCard;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./HamburgerMenu.module.scss";
import {useSelector} from "react-redux";

const HamburgerMenu = () => {
    const [opened, setOpened] = useState(false);
    const navigate = useNavigate();
    const page = useSelector((state) => state.ui.page);

    const handleClick = () => {
        setOpened(!opened);
    };

    const handleNavClick = (page) => {
        navigate(`/${page}`);
        setOpened(false);
    };

    return (
        <div className={styles.hamburgerMenu}>
            <button
                className={`${styles.menu} ${opened ? styles.opened : ""}`}
                onClick={handleClick}
                onMouseLeave={() => setOpened(false)}
                aria-label="Main Menu"
                aria-expanded={opened}
            >
                <svg width="50" height="50" viewBox="0 0 100 100">
                    <path
                        className={`${styles.line} ${styles.line1}`}
                        d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                    />
                    <path className={`${styles.line} ${styles.line2}`} d="M 20,50 H 80" />
                    <path
                        className={`${styles.line} ${styles.line3}`}
                        d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                    />
                </svg>
            </button>

            <div className={`${styles.menuItems} ${opened ? styles.menuOpen : styles.menuClose}`}>
        <span className={styles.menuItem} onClick={() => handleNavClick("auctions")}
              style={{
                  fontWeight: page === "auctions" && "bold",
                  color: page === "auctions" && "black",
              }}>
          müzayedeler
        </span>
                <span className={styles.menuItem} onClick={() => handleNavClick("hemen-al")}
                      style={{
                          fontWeight: page === "products" && "bold",
                          color: page === "products" && "black",
                      }}>

          hemen al
        </span>
                <span className={styles.menuItem} onClick={() => handleNavClick("artists")}
                      style={{
                          fontWeight: page === "artists" && "bold",
                          color: page === "artists" && "black",
                      }}>

          sanatçılar
        </span>
                <span className={styles.menuItem} onClick={() => handleNavClick("blog")}
                      style={{
                          fontWeight: page === "blog" && "bold",
                          color: page === "blog" && "black",
                      }}>

          blog
        </span>
                <span className={styles.menuItem} onClick={() => handleNavClick("contact")}
                      style={{
                          fontWeight: page === "contact" && "bold",
                          color: page === "contact" && "black",
                      }}>

          iletişim
        </span>
                <span className={styles.menuItem} onClick={() => handleNavClick("about")}
                      style={{
                          fontWeight: page === "about" && "bold",
                          color: page === "about" && "black",
                      }}>
          hakkımızda
        </span>
            </div>
        </div>
    );
};

export default HamburgerMenu;

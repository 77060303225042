import classes from "./ImageFullScreen.module.scss";
import { Fragment, useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";

const ImageFullScreen = (props) => {
  const [shownImage, setShownImage] = useState(0);
  const [images, setImages] = useState(props.imageUrls);
  const [name, setName] = useState(props.name);
  useEffect(() => {
    const imgElement = document.getElementById("1");
    imgElement.classList.add(classes.fadeInClass);
    setTimeout(() => {
      imgElement.classList.remove(classes.fadeInClass);
    }, 500);
  }, [shownImage]);

  function handleLeft() {
    setShownImage((prev) => {
      if (prev === 0) {
        return images.length - 1;
      } else {
        return prev - 1;
      }
    });
  }

  function handleRight() {
    setShownImage((prev) => {
      if (prev === images.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  }
  return (
    <Fragment>
      <div className={classes.backdrop} onClick={props.onClose}></div>
      <div className={classes.modal}>
        <div className={classes.modalHeader}>
          <span className={classes.modalHeaderText}>{props.name}</span>
          <FaTimes
            onClick={props.onClose}
            color="white"
            className={classes.modalHeaderButton}
            size={25}
          />
        </div>
        <div className={classes.modalContent}>
          {images &&
          images[shownImage] ===
            "https://muzayede-images.s3.eu-central-1.amazonaws.com/auction-products/17-2" ? (
            <video id="1" className={classes.img} controls>
              <source src={images[shownImage]} type="video/mp4" />
            </video>
          ) : (
            <img
              id="1"
              className={classes.img}
              src={images && images[shownImage].url}
            />
          )}

          <FaChevronLeft
            style={{
              position: "absolute",
              display: images && images.length === 1 ? "none" : "block",
              left: 10,
              top: "45%",
              cursor: "pointer",
            }}
            color="white"
            size={40}
            onClick={handleLeft}
          ></FaChevronLeft>
          <FaChevronRight
            style={{
              position: "absolute",
              right: 10,
              display: images && images.length === 1 ? "none" : "block",
              top: "45%",
              cursor: "pointer",
            }}
            size={40}
            color="white"
            onClick={handleRight}
          ></FaChevronRight>
        </div>
      </div>
    </Fragment>
  );
};

export default ImageFullScreen;

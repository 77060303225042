import React, {useEffect, useState} from 'react';
import classes from './PersonalInformation.module.scss';
import {useNavigate} from "react-router-dom";
import {get_request, patch_request, put_request} from "../../utils/ApiHandlers";
import InputMask from "react-input-mask";
import toastr from "toastr";
import {useSelector} from "react-redux";

const PersonalInformation = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const [editMode, setEditMode] = useState(false);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [userData, setUserData] = useState({
        name: '',
        surname: '',
        email: '',
        phone: '',
        address: '',
        birthDate: ''
    });

    useEffect(() => {
        const fetchUserData = async () => {
            const response = await get_request(navigate, '/api/v1/users/me', user);
            if (response.status === 200) {
                let userData = response.data;

                // Doğum tarihini formatla
                if (userData.birthDate) {
                    const [day, month, year] = userData.birthDate.split('-');
                    const formattedDate = `${year}-${month}-${day}`;
                    userData.birthDate = formattedDate;
                }

                setUserData(userData);
            }
        };

        fetchUserData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevUserData) => ({
            ...prevUserData,
            [name]: value
        }));
    };

    const handleSave = async () => {
        const updatedUserData = {
            email: userData.email,
            phone: userData.phone,
            address: userData.address
        };
        try {
            const response = await patch_request(navigate, '/api/v1/users', updatedUserData);
            if (response.status === 200) {
                setEditMode(false);
                toastr.success('Bilgileriniz başarıyla güncellendi.');
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    return (
            <div className={classes.signup__form}>
                <input
                    className={classes.signup__form__input}
                    type="text"
                    placeholder="Ad"
                    name="name"
                    value={userData.name || ''} // Eğer undefined ise boş string göster
                    disabled // Her zaman sadece okunabilir olacak
                />
                <input
                    className={classes.signup__form__input}
                    type="text"
                    placeholder="Soyad"
                    name="surname"
                    value={userData.surname || ''} // Eğer undefined ise boş string göster
                    disabled // Her zaman sadece okunabilir olacak
                />
                <input
                    className={classes.signup__form__input}
                    type="email"
                    placeholder="E-posta"
                    name="email"
                    value={userData.email || ''} // Eğer undefined ise boş string göster
                    onChange={handleInputChange}
                    disabled={!editMode} // Sadece editMode true olduğunda düzenlenebilir olacak
                />
                <InputMask
                    mask="999 999 99 99"
                    value={userData.phone || ''}
                    onChange={handleInputChange}
                    disabled={!editMode}
                >
                    {() => (
                        <input
                            className={classes.signup__form__input}
                            type="tel"
                            placeholder="Telefon"
                            name="phone"
                            disabled={!editMode} // Sadece editMode true olduğunda düzenlenebilir olacak
                        />
                    )}
                </InputMask>
                <input
                    className={classes.signup__form__input}
                    type="text"
                    placeholder="Adres"
                    name="address"
                    value={userData.address || ''} // Eğer undefined ise boş string göster
                    onChange={handleInputChange}
                    disabled={!editMode} // Sadece editMode true olduğunda düzenlenebilir olacak
                />
                <input
                    className={[`${classes.signup__form__input} ${classes.date_input}`].join(' ')}
                    type="date"
                    placeholder="Doğum Tarihi"
                    name="birthDate"
                    value={userData.birthDate || ''} // Eğer undefined ise boş string göster
                    disabled={true} // Düzenlenemez hale getir
                />
                <div className={classes.editButtons}>
                    {!editMode ? (
                        <button type="button" onClick={() => setEditMode(true)}>düzenle</button>
                    ) : (
                        <>
                            <button type="button" onClick={handleSave}>kaydet</button>
                            <button type="button" onClick={() => setEditMode(false)}>iptal</button>
                        </>
                    )}
                </div>
            </div>
    );
};

export default PersonalInformation;

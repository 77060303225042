import { api } from "../api/axios";
import {HandleApiError} from "./ErrorHandler";


async function refreshToken() {
    try {
        const response = await api.get('/api/v1/auth/refresh', {withCredentials: true});
        let user = JSON.parse(sessionStorage.getItem('user'))
        user.user.token = response.data.token
        sessionStorage.setItem('user', JSON.stringify(user))
        return response.data.token;
    } catch (error) {
        console.error('Token yenileme hatası:', error);
        return null;
    }
}

api.interceptors.response.use(
    response => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            const newToken = await refreshToken();

            if (newToken) {
                originalRequest.headers.Authorization = `Bearer ${newToken}`;
                return api(originalRequest);
            } else {
                HandleApiError(error, false);
            }
        }

        return Promise.reject(error);
    }
);

function TokenHandler() {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user && user.user.token) {
        return `Bearer ${user.user.token}`;
    }
    return "";
}

export function get_request(navigate, url, user) {
  let header = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
      "Authorization": TokenHandler(),
  };

  const response = api.get(url, {
    headers: header,
  }).catch((e) => {
      HandleApiError(e, false);
    return e.message;
  });
  return response;
}

export function post_request(navigate, url, body, user) {
  let header = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  if (url !== "/api/v1/auth") {
    header["Authorization"] = TokenHandler();
  }
  const response = api
    .post(url, body, {
        headers: header,
        withCredentials: true,
    })
    .catch((e) => {
      HandleApiError(e);
      return e.response.data;
    });
  return response;
}

export function put_request(navigate, url, body, user) {
  let header = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Authorization": TokenHandler(),
  };

    const response = api
        .put(url, body, {
        headers: header
        })
        .catch((e) => {
            HandleApiError(e);
        return e.response.data;
        });
    return response;
}

export function patch_request(navigate, url, body, user) {
  let header = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Authorization": TokenHandler(),
    };
    const response = api
        .patch(url, body, {
        headers: header
        })
        .catch((e) => {
            HandleApiError(e);
        return e.response.data;
        });
    return response;
}

export function delete_request(navigate, url, user) {
    let header = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": TokenHandler(),
    };
    const response = api
        .delete(url, {
            headers: header
        })
        .catch((e) => {
            HandleApiError(e);
            return e.response.data;
        });
    return response;
}

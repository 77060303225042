import classes from "./SendEmail.module.scss";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../api/axios";
import { MdOutlineEmail } from "react-icons/md";
import Circles from "react-loading-icons/dist/esm/components/circles";
import Oval from "react-loading-icons/dist/esm/components/oval";

const SendEmail = (props) => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const { email } = useParams();
  return (
    <div
      className={[
        `${classes.verification} ${
          props.currentPage ? classes.in : classes.out
        }`,
      ]}
    >
      <div className={classes.modal}>
        <MdOutlineEmail color="black" size={100} />
        <span className={classes.modal__title}>Hesabınızı doğrulayın</span>
        <span className={classes.modal__message}>
          aşağıdaki mail adresine bir doğrulama maili gönderildi:
        </span>
        <span className={classes.modal__message}>
          <b>{email}</b>
        </span>
        <span
          className={classes.span2}
          style={{ textAlign: "center" }}
        >
          gereksiz kutunuzu kontrol etmeyi unutmayınız
        </span>
        {loading && (
          <div className={classes.spin_container}>
            <Oval stroke="black" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SendEmail;

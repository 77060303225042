import React from "react";
import classes from "./Footer.module.scss";
import { SocialIcon } from "react-social-icons";


const Footer = () => {
    return (
        <footer className={classes.footer}>
            <div className={classes.footer__quarter}>
        <span className={`${classes.footer__quarter__text} ${classes.footer__quarter__text__bold}`}>
          iletişim
        </span>
                <span className={`${classes.footer__quarter__text} ${classes.footer__quarter__text__light}`}>
          online müzayede kuralları
        </span>
                <span className={classes.footer__quarter__text}>
          müzayede katılım şartları
        </span>
                <span className={classes.footer__quarter__text}>
          gizlilik ve güvenlik
        </span>
                <span className={classes.footer__quarter__text}>
          ön bilgilendirme formu
        </span>
                <span className={classes.footer__quarter__text}>
          mesafeli satış sözleşmesi
        </span>
                <span className={classes.footer__quarter__text}>
          sipariş ve teslimat
        </span>
            </div>
            <div className={classes.footer__quarter}>
        <span className={classes.footer__quarter__text}>
          kullanıcı sözleşmesi
        </span>
                <span className={classes.footer__quarter__text}>
          gizlilik politikası
        </span>
                <span className={classes.footer__quarter__text}>
          ön bilgilendirme formu
        </span>
                <span className={classes.footer__quarter__text}>
          mesafeli satış sözleşmesi
        </span>
                <span className={classes.footer__quarter__text}>iptal ve iade koşulları</span>
                <span className={classes.footer__quarter__text}>
          banka hesap bilgileri
        </span>
                <span className={classes.footer__quarter__text}>
          kişisel verilerin korunması
        </span>
            </div>
            <div className={classes.footer__quarter}>
                <span className={classes.footer__quarter__text}>çerez politikası</span>
                <span className={classes.footer__quarter__text}>
          paketleme ve kargo
        </span>
                <span className={classes.footer__quarter__text}>
          nasıl eser alırım?
        </span>
                <span className={classes.footer__quarter__text}>
          nasıl eser satarım?
        </span>
                <span className={classes.footer__quarter__text}>
          ekspertiz ve sigortalama
        </span>
                <span className={classes.footer__quarter__text}>
          sıkça sorulan sorular
        </span>
            </div>
            <div className={classes.footer__info}>
                <div className={classes.footer__info__contact}>
          <span className={classes.footer__info__contact__address}>
            gülbahçe mahallesi, no:12 daire:2
            posta kodu:41300 urla/izmir
          </span>
                    <span className={classes.footer__info__contact__phone}>
            telefon: +90 (555) 875 6452
          </span>
                    <span className={classes.footer__info__contact__email}>
            e-posta: info@sitelogosu.com
          </span>
                </div>
                <div className={classes.footer__info__socials}>
                    <SocialIcon
                        url="https://www.instagram.com/whats_upart"
                        bgColor="#000000"
                        fgColor="#FFFFFF"
                        className="social-icon"
                        target="_blank"
                    />
                    <SocialIcon
                        url="https://x.com/upartauction"
                        bgColor="#000000"
                        fgColor="#FFFFFF"
                        className="social-icon"
                        target="_blank"
                    />
                    <SocialIcon
                        url="https://youtube.com"
                        bgColor="#000000"
                        fgColor="#FFFFFF"
                        className="social-icon"
                        target="_blank"
                    />
                </div>
            </div>
        </footer>
    );
};

export default Footer;

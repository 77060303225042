import React, { useState } from 'react';
import classes from './ChangePassword.module.scss';
import { useNavigate } from 'react-router-dom';
import { patch_request } from '../../utils/ApiHandlers';
import toastr from 'toastr';
import { useSelector } from 'react-redux';

const ChangePassword = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const [passwordData, setPasswordData] = useState({
        newPassword: '',
        confirmPassword: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPasswordData((prevPasswordData) => ({
            ...prevPasswordData,
            [name]: value
        }));
    };

    const handleChangePassword = async () => {
        if (passwordData.newPassword !== passwordData.confirmPassword) {
            toastr.error('Şifreler uyuşmuyor.');
            return;
        }

        try {
            const response = await patch_request(navigate, '/api/v1/users/password', passwordData, user);
            if (response.status === 200) {
                toastr.success('Şifreniz başarıyla güncellendi.');
                setPasswordData({
                    newPassword: '',
                    confirmPassword: ''
                });
            }
        } catch (error) {
            console.error('Error changing password:', error);
            toastr.error('Şifre güncellenirken bir hata oluştu.');
        }
    };

    return (
        <div className={classes.changePassword}>
        <div className={classes.changePasswordForm}>
            <input
                className={classes.changePasswordInput}
                type="password"
                placeholder="yeni şifre"
                name="newPassword"
                value={passwordData.newPassword}
                onChange={handleInputChange}
            />
            <input
                className={classes.changePasswordInput}
                type="password"
                placeholder="yeni şifreyi onayla"
                name="confirmPassword"
                value={passwordData.confirmPassword}
                onChange={handleInputChange}
            />
            <div className={classes.changePasswordButtons}>
                <button type="button" onClick={handleChangePassword}>şifreyi değiştir</button>
            </div>
        </div>
        </div>
    );
};

export default ChangePassword;

import React from 'react';
import Popup from 'reactjs-popup';
import classes from './Confirmation.module.scss';

const ConfirmationModal = ({ isVisible, onConfirm, onCancel, message }) => {
    return (
        <Popup open={isVisible} onClose={onCancel} overlayStyle={{  background:"rgba(0,0,0,.5)"}} modal>
            <div className={classes['popup-content']}>
                <h3>{message}</h3>
                <div className={classes.btnContainer}>
                    <button onClick={onConfirm} className={classes.btn}>onayla</button>
                </div>
            </div>
        </Popup>
    );
};

export default ConfirmationModal;

import React, { forwardRef } from "react";
import classes from "./CustomInput.module.scss";

const CustomInput = forwardRef(({ value, onChange, ...rest }, ref) => (
  <input
    maxLength={13}
    ref={ref}
    value={value}
    onChange={onChange}
    className={classes.signup__form__input}
    placeholder="5XX XXX XX XX"
    {...rest} // Bu sayede diğer props'lar da input'a iletilir
  />
));

export default CustomInput;

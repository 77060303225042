import React from "react";
import classes from "./PastAuctionCard.module.scss";
import { useNavigate } from "react-router-dom";

const PastAuctionCard = (props) => {
  const navigate = useNavigate();

  return (
    <div className={classes.current_auction}
         onClick={() => {
        navigate(`/auctions/auction/${props.id}/${props.name}`);
    }} >
        <div className={classes.image_container}>
            <img className={classes.img} src={props.image.url} alt={props.image.alt}/>
        </div>
        <div className={classes.auction_info_container}>
            <div style={{display: "flex", flexDirection: "column" }}>
          <span className={classes.auction_name}>{props.name}</span>
          <span className={classes.info_text} style={{ fontSize: 20 }}>
            müzayede no: {props.muzayede_no}
          </span>
        </div>
      </div>
      <div className={classes.auction_date_container}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span className={classes.info_text} style={{fontSize: 18}}>
              <span style={{color: 'red'}}>
                {(new Date(props.start_date).toLocaleDateString('tr-TR', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric'
                })).toLowerCase()}
              </span>
              <span style={{color: 'black'}}> I </span>
              <span style={{color: 'black'}}>
                {new Date(props.start_date).toLocaleTimeString('tr-TR', {
                    hour: '2-digit',
                    minute: '2-digit'
                }).replace(':', '.')}
              </span>
          </span>
            <span className={classes.info_text} style={{fontSize: 18}}>
              <span style={{color: 'red'}}>
                {(new Date(props.end_date).toLocaleDateString('tr-TR', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric'
                })).toLowerCase()}
              </span>
              <span style={{color: 'black'}}> I </span>
              <span style={{color: 'black'}}>
                {new Date(props.end_date).toLocaleTimeString('tr-TR', {
                    hour: '2-digit',
                    minute: '2-digit'
                }).replace(':', '.')}
              </span>
          </span>
        </div>
      </div>
        <div className={classes.btn_container}>
            <button
                className={classes.current_auction__btn}
                onClick={() => {
                    navigate(`/auctions/auction/${props.id}/${props.name}`);
                }}
            >
                müzayede sonuçları
            </button>
        </div>
    </div>
  );
};

export default PastAuctionCard;

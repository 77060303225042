import React, { useEffect, useState } from "react";
import classes from "./AuctionDetails.module.scss";
import AuctionProductCard from "../components/auction_product/AuctionProductCard";
import { get_request } from "../utils/ApiHandlers";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Oval } from "react-loading-icons";
import { useSubscription } from "react-stomp-hooks";
import ImageFullScreen from "../components/modals/ImageFullScreen";
import ScrollTop from "../utils/ScrollTop";
import ArtistSelect from "../components/select/ArtistSelect";

const AuctionDetails = (props) => {
  ScrollTop()
  props.changePage("auctions")

  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const { auction_id } = useParams();
  const { auction_name } = useParams();

  const [auctionState, setAuctionState] = useState(false);
  // Products state'i bir nesne olarak tutuyoruz, id'leri key olarak kullanıyoruz.
  const [products, setProducts] = useState({});
  //const [tempProducts, setTempProducts] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMoreProducts, setHasMoreProducts] = useState(false);
  const [hasMoreWatchList, setHasMoreWatchList] = useState(true);
  const [bidPlacedOnly, setBidPlacedOnly] = useState(false);
  const [watchListOnly, setWatchListOnly] = useState(false);
  const [fullImageUrls, setFullImageUrls] = useState([]);
  const [imageFullScreenVisible, setImageFullScreenVisible] = useState(false);
  const [lotCount, setLotCount] = useState(0);
  const [artists, setArtists] = useState([]);

  const [executeFilter, setExecuteFilter] = useState(false);
  const [isFiltersChanged, setIsFiltersChanged] = useState(false);

  const [filters, setFilters] = useState(new Map());

  useEffect(() => {
    const fetchAuctionArtists = async () => {
      const response = await get_request(navigate, `/api/v1/auctions/${auction_id}/artists`)
      if (response.status === 200) {
        setArtists(response.data);
      }
    }

    fetchAuctionArtists();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      page > 0 ? setIsLoadMoreLoading(true) : setIsLoading(true);
      const response = await get_request(
        navigate,
        `/api/v1/auctions/${auction_id}/lots/new?page=${page}&size=12&filter=${generateFilter(filters)}`
      );
      if (response.status === 200) {
        const fetchedProducts = response.data.lots.content.reduce(
          (acc, product) => {
            acc[product.id ? product.id : product.lotNumber] = product;
            return acc;
          },
          {}
        );
        response.data.lots.content.length > 0 && setAuctionState(response.data.lots.content[0].isCurrentAuction);

        setProducts((prevProducts) => ({
          ...prevProducts,
          ...fetchedProducts,
        }));

        setHasMoreProducts(
          Object.keys(products).length + Object.keys(fetchedProducts).length <
            response.data.lots.page.totalElements
        );
        setLotCount(response.data.lots.page.totalElements);
      }
      page > 0 ? setIsLoadMoreLoading(false) : setIsLoading(false);
    };


    fetchProducts();
    }, [page, auction_id, executeFilter]);


  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const closeImageFullScreen = () => {
    setImageFullScreenVisible(false);
  };

  const openImageFullScreen = (imageUrls) => {
    setFullImageUrls(imageUrls);
    setImageFullScreenVisible(true);
  };

  function generateFilter(filters) {
    if (filters.size === 0) return "";
    return Array.from(filters.entries())
        .map(([key, value]) => `${key}${value}`)
        .join(';');
  }


  const executeFilters = () => {
    setExecuteFilter(prev => !prev);
    setIsFiltersChanged(false);
    setPage(0);
    setProducts([]);
  }

  const executeFiltersIfChanged = () => {
    isFiltersChanged && executeFilters()
  }

  const handleLotFilterKeyDown = (event) => {
    if (event.key === 'Enter') {
      executeFiltersIfChanged();
    }
  };

  const handleArtistChange = (selectedOption) => {
    if (selectedOption) {
      setFilters((prev) => prev.set("artistId==", selectedOption.value));
    } else {
      setFilters((prev) => {
        const newMap = new Map(prev);
        newMap.delete("artistId==");
        return newMap;
      });
    }

    executeFilters();
  };

  const handleLotChange = (event) => {
    if (event.target.value !== "") {
      setFilters((prev) => prev.set("lotNumber==", event.target.value) )
    } else {
      setFilters((prev) => {
        prev.delete("lotNumber==")
        return prev
      })
    }
    !isFiltersChanged && setIsFiltersChanged(true);
  }

  const handleNameChange = (event) => {
    if (event.target.value !== "") {
      setFilters((prev) => prev.set("name=ilike=", event.target.value));
    } else {
      setFilters((prev) => {
        prev.delete("name=ilike=")
        return prev
      })
    }
    !isFiltersChanged && setIsFiltersChanged(true);
  }


  useSubscription("/bids", (message) => {
    const messageBody = JSON.parse(message.body);
    const productId = messageBody.lotId;

    if (products[productId]) {
      let updatedProducts = { ...products };
      updatedProducts[productId].currentPrice = messageBody.lotPrice;
      updatedProducts[productId].isBidPlaced =
          updatedProducts[productId].isBidPlaced || messageBody.userId === user.user.id;
      updatedProducts[productId].isWinner =
        updatedProducts[productId].isBidPlaced && user.user.id === messageBody.userId
      updatedProducts[productId].bidCount = messageBody.bidCount;

      let endTimeDate = new Date(updatedProducts[productId].saleEndTime);
      endTimeDate.setMinutes(endTimeDate.getMinutes() + messageBody.extraTime);
      updatedProducts[productId].saleEndTime = new Date(endTimeDate).toISOString();

      setProducts(updatedProducts);
    }
  });

  return (
    <div
      className={[
        `${classes.auction_details} ${
          props.currentPage ? classes.in : classes.out
        }`,
      ]}
    >
      {imageFullScreenVisible && (
        <ImageFullScreen
          imageUrls={fullImageUrls}
          onClose={closeImageFullScreen}
        />
      )}
      <section className={classes.auction_details__header}>
        <span
          className={[
            `${classes.auction_details__header__text} ${classes.bold}`,
          ]}
        >
          {auction_name}
        </span>
        <span
          style={{ marginBottom: "20px" }}
          className={classes.auction_details__header__text}
        >
          {products && `${lotCount} lot`}
        </span>

        <div className={classes.form}>
          <div className={classes.form_inner}>
          <input
            type="text"
            placeholder="ara"
            className={classes.form__text_inp}
            onChange={handleNameChange}
            onBlur={executeFiltersIfChanged}
            onKeyDown={handleLotFilterKeyDown}
          />
          </div>
          <div className={classes.form_inner}>
          <input
            type="text"
            placeholder="lot no ile ara"
            className={classes.form__text_inp}
            onChange={handleLotChange}
            onBlur={executeFiltersIfChanged}
            onKeyDown={handleLotFilterKeyDown}
          />
          </div>
          <div className={classes.form_inner} style={{padding: 0, border:"none"}}>
            <ArtistSelect
                artists={artists}
                handleArtistChange={handleArtistChange}
                classes={classes.form__text_inp}
            />
          </div>
          {user.loggedIn && auctionState && (
              <>
                <div className={classes.form_inner}
                     style={{
                       backgroundColor: bidPlacedOnly ? "black" : "white"
                     }}
                >
                <button
                    className={classes.toggle_btn}
                    style={{
                      color: bidPlacedOnly ? "white" : "black",
                      minWidth: "max-content"
                    }}
                    onClick={() => {
                      setBidPlacedOnly(!bidPlacedOnly);
                      setFilters((prev) => {
                        executeFilters()
                        if (!bidPlacedOnly) {
                          return prev.set("isBidPlaced", !bidPlacedOnly)
                        }
                        prev.delete("isBidPlaced");
                        return prev
                      })
                    }}
                >
                  {bidPlacedOnly ? "teklif verdiklerim" : "tüm ürünler"}
                </button>
                </div>
                <div className={classes.form_inner}
                     style={{
                       backgroundColor: watchListOnly ? "black" : "white",
                     }}
                >
                <button
                className={classes.toggle_btn}
                style={{
                  color: watchListOnly ? "white" : "black",
                  minWidth: "max-content"
                }}
                onClick={() => {
                  setWatchListOnly(!watchListOnly);
                  setFilters((prev) => {
                    executeFilters()
                    if (!watchListOnly) {
                      return prev.set("isInWatchlist", !watchListOnly)
                    }
                    prev.delete("isInWatchlist");
                    return prev
                  })
                }}
                >
                favorilerim
              </button>
                </div>
            </>
          )}
        </div>
      </section>
      {isLoading && (
          <div className={classes.spin_container}>
            <Oval stroke="black"/>
          </div>
      )}

      {products && (
          <div className={classes.auction_product_container}>
          {Object.values(products).map((item) =>
            bidPlacedOnly ? (
              item.isBidPlaced && (
                <AuctionProductCard
                  openImageFullScreen={openImageFullScreen}
                  imageUrls={[item.coverImage].concat(item.assets)}
                  key={item.id}
                  id={item.id}
                  lotNumber={item.lotNumber}
                  artistFullName={item.artistName}
                  name={item.name}
                  currentPrice={item.currentPrice}
                  description={item.description}
                  startTime={item.saleStartTime}
                  endTime={item.saleEndTime}
                  isOwned={item.isWinner}
                  isBidded={item.isBidPlaced}
                  bidCount={item.bidCount}
                  isCurrentAuction={item.isCurrentAuction}
                  isBiddingEnabled={item.isBiddingEnabled}
                  isInWatchList={item.isInWatchList}
                  bannerText={item.bannerText}
                />
              )
            ) : (
              <AuctionProductCard
                openImageFullScreen={openImageFullScreen}
                imageUrls={[item.coverImage].concat(item.assets)}
                key={item.id}
                id={item.id}
                lotNumber={item.lotNumber}
                artistFullName={item.artistName}
                name={item.name}
                currentPrice={item.currentPrice}
                description={item.description}
                startTime={item.saleStartTime}
                endTime={item.saleEndTime}
                isOwned={item.isWinner}
                isBidded={item.isBidPlaced}
                bidCount={item.bidCount}
                isCurrentAuction={item.isCurrentAuction}
                isBiddingEnabled={item.isBiddingEnabled}
                isInWatchList={item.isInWatchList}
                bannerText={item.bannerText}
              />
            )
          )}
        </div>
      )}
      <div>
        {Object.keys(products).length === 0 && !isLoading && (
            <span style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              fontFamily: 'Gilroy-Light',
              fontSize: '0.9rem',
              marginTop: '1.5rem',
            }}>eser bulunamadı</span>
        )}
      </div>
      <div className={classes.products__header}>
        {
          hasMoreProducts && !isLoading &&
          (isLoadMoreLoading ? (
            <div className={classes.spin_container}>
              <Oval stroke="black" />
            </div>
          ) : (
            <button onClick={loadMore} className={classes.load_more_button}>
              daha fazla yükle
            </button>
          ))}
      </div>
    </div>
  );
};

export default AuctionDetails;

import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    page: "homepage",
  },
  reducers: {
    changePage: {
      reducer(state, action) {
        const { page, previous, keepPrevious } = action.payload;
        state.page = page;
        if (!keepPrevious) {
          state.previous = previous;
        }
      },
      prepare(page, previous, keepPrevious) {
        return {
          payload: {
            page,
            previous,
            keepPrevious
          }
        };
      }
    },
  },
});

export const uiActions = uiSlice.actions;
export default uiSlice;

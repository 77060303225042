import React, { useEffect, useState } from "react";
import classes from "./Artists.module.scss";

import ArtistCard from "../components/artists/ArtistCard";
import { get_request } from "../utils/ApiHandlers";
import Oval from "react-loading-icons/dist/esm/components/oval";
import { useNavigate } from "react-router-dom";
import ScrollTop from "../utils/ScrollTop";

const Artists = (props) => {
  ScrollTop()
  props.changePage("artists")

  const [artists, setArtists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  const [page, setPage] = useState(0); // Sayfa numarasını tutacak state
  const [hasMoreArtists, setHasMoreArtists] = useState(true); // Daha fazla sanatçı olup olmadığını kontrol eden state
  const navigate = useNavigate();

  const [executeFilter, setExecuteFilter] = useState(false);
  const [isFiltersChanged, setIsFiltersChanged] = useState(false);

  const [filters, setFilters] = useState(new Map());


  useEffect(() => {
    const fetchArtists = async () => {
      page > 0 ? setIsLoadMoreLoading(true) : setIsLoading(true);
      const response = await get_request(navigate, `/api/v1/artists?page=${page}&size=15&sort=fullName&filter=${generateFilter(filters)}`);
      if (response.status === 200) {
        const fetchedArtists = response.data.content;
        const updatedArtists = [...artists, ...fetchedArtists]; // Mevcut sanatçıların üstüne ekleme yap
        setArtists(updatedArtists);
        setHasMoreArtists(updatedArtists.length < response.data.page.totalElements); // Daha fazla sanatçı var mı kontrol et
      }
      page > 0 ? setIsLoadMoreLoading(false) : setIsLoading(false);
    };
    fetchArtists();
  }, [page, executeFilter]); // Sayfa numarası değiştiğinde yeni sanatçıları getir

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1); // Sayfa numarasını artırarak daha fazla sanatçı yükle
  };

  function generateFilter(filters) {
    if (filters.size === 0) return "";
    return Array.from(filters.entries())
        .map(([key, value]) => `${key}${value}`)
        .join(';');
  }

  const handleNameChange = (event) => {
    if (event.target.value !== "") {
      setFilters((prev) => prev.set("fullName=ilike=", event.target.value));
    } else {
      setFilters((prev) => {
        prev.delete("fullName=ilike=")
        return prev
      })
    }
    !isFiltersChanged && setIsFiltersChanged(true);
  }

  const executeFiltersIfChanged = () => {
    isFiltersChanged && executeFilters()
  }

  const handleLotFilterKeyDown = (event) => {
    if (event.key === 'Enter') {
      executeFiltersIfChanged();
    }
  };

  const executeFilters = () => {
    setExecuteFilter(prev => !prev);
    setIsFiltersChanged(false);
    setPage(0);
    setArtists([]);
  }

  return (
      <div
          className={[
            `${classes.artists} ${props.currentPage ? classes.in : classes.out}`,
          ]}
      >
          <div className={classes.dropdown_container}>
            <input
                type="text"
                placeholder="sanatçı ara"
                className={classes.dropdown}
                onChange={handleNameChange}
                onBlur={executeFiltersIfChanged}
                onKeyDown={handleLotFilterKeyDown}
            />
          </div>
        <div className={classes.artist_container}>
          {isLoading && (
              <div className={classes.spin_container}>
                <Oval stroke="black"/>
              </div>
          )}
          {artists &&
              artists.map((artist) => {
                return (
                    <ArtistCard
                        id={artist.id}
                        key={artist.id}
                        imageUrl={artist.profileImage.url}
                        fullName={artist.fullName}
                    />
                );
              })}
        </div>
        <div className={classes.load_more_container}>
          {!isLoading && hasMoreArtists && (
              isLoadMoreLoading ? (
                  <div className={classes.spin_container}>
                    <Oval stroke="black" />
                  </div>
              ) : (
                  <button onClick={loadMore} className={classes.load_more_button}>
                    daha fazla yükle
                  </button>
              )
          )}
        </div>
      </div>
  );
};

export default Artists;
import React, {useEffect, useState} from "react";
import classes from "./Blog.module.scss";
import BlogCard from "../components/blog/BlogCard";
import {get_request} from "../utils/ApiHandlers";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {format_datetime} from "../utils/DateHandlers";

const Blog = (props) => {
    props.changePage("blog")

    const [blogs, setBlogs] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchBlogs = async () => {
            const response = await get_request(navigate, `/api/v1/blogs`)
            if (response.status === 200) {
                setBlogs(response.data.content);
            }
        }
        fetchBlogs()
    }, []);

    return (
        <div className={classes.blogContainer}>
        <div className={classes.blogGrid}>
            {blogs.map((blog) => (
                <BlogCard
                    key={blog.id}
                    id={blog.id}
                    name={blog.name}
                    coverImage={blog.coverImage}
                    date={new Date(blog.createDate).toLocaleDateString()}
                    content={blog.content}
                />
            ))}
        </div>
        </div>
    );
};

export default Blog;

import React, { useEffect, useState } from 'react';
import PersonalInformation from '../components/account/PersonalInformation';
import classes from './Account.module.scss';
import ChangePassword from "../components/account/ChangePassword";
import MyWinnings from '../components/account/MyWinnings';
import { get_request } from "../utils/ApiHandlers";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import userSlice from "../store/userSlice";
import MyOrders from "../components/account/MyOrders";
import { ChevronRight } from 'lucide-react';
import ScrollTop from "../utils/ScrollTop";

const AccountPage = (props) => {
    ScrollTop()
    props.changePage("account")

    const [activeSection, setActiveSection] = useState('personalInformation');
    const [points, setPoints] = useState(0);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const user = useSelector((state) => state.user.user);
    const navigate = useNavigate();

    const dispatch = useDispatch();


    useEffect(() => {
        const fetchUserData = async () => {
            const response = await get_request(navigate, '/api/v1/users/me', user);
            if (response.status === 200) {
                setPoints(response.data.point);
            }
        };
        fetchUserData();

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const logout = () => {
        sessionStorage.setItem("user", JSON.stringify(userSlice.getInitialState()));
        window.location.replace('/login')
    }

    const renderContent = () => {
        switch (activeSection) {
            case 'myWinnings':
                return <MyWinnings />;
            case 'myOrders':
                return <MyOrders />;
            case 'myUppoints':
                return <div>My Uppoints</div>;
            case 'password':
                return <ChangePassword />;
            case 'personalInformation':
            default:
                return <PersonalInformation />;
        }
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleSectionClick = (section) => {
        setActiveSection(section);
        if (isMobile) {
            setIsSidebarOpen(false);
        }
    };

    return (
        <div className={classes.accountPage}>
            {isMobile && (
                <>
                    <div
                        className={`${classes.sidebarToggle} ${isSidebarOpen ? classes.open : ''}`}
                        onClick={toggleSidebar}
                    >
                        <ChevronRight size={24} />
                    </div>
                    <div
                        className={`${classes.overlay} ${isSidebarOpen ? classes.open : ''}`}
                        onClick={() => setIsSidebarOpen(false)}
                    ></div>
                </>
            )}
            <div className={`${classes.sidebar} ${isMobile && isSidebarOpen ? classes.open : ''}`}>
                <ul>
                    <li
                        onClick={() => handleSectionClick('myWinnings')}
                        className={activeSection === 'myWinnings' ? classes.active : ''}
                    >
                        eserlerim
                    </li>
                    <li
                        onClick={() => handleSectionClick('myOrders')}
                        className={activeSection === 'myOrders' ? classes.active : ''}
                    >
                        siparişlerim
                    </li>
                    <li
                        onClick={() => handleSectionClick('password')}
                        className={activeSection === 'password' ? classes.active : ''}
                    >
                        şifre değiştir
                    </li>
                    <li
                        onClick={() => handleSectionClick('personalInformation')}
                        className={activeSection === 'personalInformation' ? classes.active : ''}
                    >
                        kişisel bilgiler
                    </li>
                    <li
                        onClick={() => logout()}
                        className={classes.logout}
                    >
                        çıkış yap
                    </li>
                </ul>
            </div>
            <div className={classes.personalInfo}>
                {renderContent()}
            </div>
        </div>
    );
};

export default AccountPage;
import { configureStore } from "@reduxjs/toolkit";
import uiSlice from "./uiSlice";
import userSlice from "./userSlice";

// LocalStorage'dan başlangıç durumunu al
const persistedState = {
  ui: JSON.parse(sessionStorage.getItem("ui")) || uiSlice.getInitialState(),
  user: JSON.parse(sessionStorage.getItem("user")) || userSlice.getInitialState(),
};

// Redux store'u oluştur ve başlangıç durumunu ayarla
const store = configureStore({
  reducer: { ui: uiSlice.reducer, user: userSlice.reducer },
  preloadedState: persistedState,
});

// Store değişikliklerini localStorage ile senkronize et
store.subscribe(() => {
  const { ui, user } = store.getState();
  sessionStorage.setItem("ui", JSON.stringify(ui));
  sessionStorage.setItem("user", JSON.stringify(user));
});

export default store;

import React, { useEffect, useState } from "react";
import classes from "./ImageContainer.module.scss";

const ImageContainer = (props) => {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (props.images && props.images.length > 0) {
      setUrl(props.images[0]);
    }
  }, [props.images]);

  useEffect(() => {
    const imgElement = document.getElementById("1");
    if (imgElement) {
      imgElement.classList.add(classes.fadeInClass);
      setTimeout(() => {
        imgElement.classList.remove(classes.fadeInClass);
      }, 500);
    }
  }, [url]);

  return (
    <div className={classes.top_section__image_container}>
      <div className={classes.big_image_container}>
        {url &&
        url ===
          "https://muzayede-images.s3.eu-central-1.amazonaws.com/auction-products/17-2" ? (
          <video
            id="1"
            className={classes.image_container__big_image}
            controls
            autoplay
          >
            <source src={url} type="video/mp4" />
          </video>
        ) : null}
        {url &&
        url.url !==
          "https://muzayede-images.s3.eu-central-1.amazonaws.com/auction-products/17-2" ? (
          <img
            onClick={() =>
              props.openImageFullScreen(props.images && props.images)
            }
            id="1"
            className={classes.image_container__big_image}
            src={url.url}
            alt={url.alt}
          />
        ) : null}
      </div>
      <div className={classes.image_container__sub_image_container}>
        {props.images &&
          props.images.map((imageUrl, index) =>
            imageUrl.url !==
            "https://muzayede-images.s3.eu-central-1.amazonaws.com/auction-products/17-2" ? (
              <img
                key={index}
                onClick={() => setUrl(imageUrl)}
                className={classes.sub_image}
                src={imageUrl.url}
                alt={imageUrl.alt}
              />
            ) : (
              <img
                key={index}
                id="1"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1CtedgWUJnCteIXbnQIKY72tuwCK6vPOw3WhmnAdSWQ&s"
                className={classes.sub_image}
                onClick={() => setUrl(imageUrl)}
              />
            )
          )}
      </div>
    </div>
  );
};

export default ImageContainer;

import React, { useState, useEffect } from 'react';
import { FaArrowLeft } from 'react-icons/fa'; // FA Icon import
import classes from './MyWinnings.module.scss';
import { get_request } from '../../utils/ApiHandlers';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {uiActions} from "../../store/uiSlice";

const MyOrders = () => {
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedOrderAssets, setSelectedOrderAssets] = useState(null);
    const [selectedArtworkIndex, setSelectedArtworkIndex] = useState(0);

    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();

    const changePage = (page) => {
        dispatch(uiActions.changePage(page));
    };

    useEffect(() => {
        const fetchOrders = async () => {
            const response = await get_request(navigate, '/api/v1/users/orders', user);
            if (response.status === 200) {
                setOrders(response.data);
            }
        };

        fetchOrders();
    }, []);

    const handleOrderClick = (order) => {
        if (order.mediaAssets) {
            setSelectedOrderAssets([order.coverImage, ...order.mediaAssets]);
        } else {
            setSelectedOrderAssets([order.coverImage]);
        }
        setSelectedOrder(order);
    };

    const handleArtworkClick = (index) => {
        setSelectedArtworkIndex(index);
    };

    const handleBackToOrders = () => {
        setSelectedOrder(null);
        setSelectedOrderAssets(null);
        setSelectedArtworkIndex(0);
    };

    return (
        <div className={classes.myWinnings}>
            {!selectedOrder ? (
                <div className={classes.gridContainer}>
                    {orders.map((order) => (
                        <div key={order.id} className={classes.artworkCard} onClick={() => handleOrderClick(order)}>
                            <img src={order.coverImage.url} alt={order.coverImage.alt} className={classes.artworkImage} />
                            <div className={classes.artworkInfo}>
                                <h3>{order.name}</h3>
                                <p>{order.artistName}</p>
                                <p>fiyat: {order.price}</p>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    <div className={classes.backButton} onClick={handleBackToOrders}>
                        <FaArrowLeft />
                    </div>

                    <div className={classes.artworkDetail}>
                        <div className={classes.artworkImageContainer}>
                            {selectedArtworkIndex > 0 && (
                                <div className={classes.leftArrow} onClick={() => handleArtworkClick(selectedArtworkIndex - 1)}></div>
                            )}
                            <img
                                src={selectedOrderAssets[selectedArtworkIndex].url}
                                alt={selectedOrderAssets[selectedArtworkIndex].alt}
                                className={classes.artworkImage}
                            />
                            {selectedArtworkIndex < selectedOrderAssets.length - 1 && (
                                <div className={classes.rightArrow} onClick={() => handleArtworkClick(selectedArtworkIndex + 1)}></div>
                            )}
                        </div>
                        <div className={classes.artworkInfoContainer}>
                            <div className={classes.artworkInfo}>
                                <h3>{selectedOrder.name}</h3>
                            </div>
                            <div className={classes.ownerInfo} onClick={() => {
                                changePage('artists');
                                navigate(`/artists/${selectedOrder.artistId}`)
                            }}>
                                <div className={classes.ownerDetail}>
                                    <h3>{selectedOrder.artistName}</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.artworkDescription}>
                        <h5>eser açıklaması</h5>
                        <p>{selectedOrder.description}</p>
                    </div>
                </>
            )}
        </div>
    );
};

export default MyOrders;

import React, {useEffect, useState} from 'react';
import {FaRegBell , FaRegTrashCan } from "react-icons/fa6";
import {delete_request, get_request, put_request} from "../../utils/ApiHandlers";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const Notifications = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [notifs, setNotifications] = useState([]);
    const [unrCount, setUnreadCount] = useState(0);

    const user = useSelector((state) => state.user.user);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchNotifications = async () => {
            if (!user.loggedIn) return;

            try {
                const response = await get_request(navigate, '/api/v1/notifications', user);
                setNotifications(response.data.notificationList.content);
                setUnreadCount(response.data.unreadCount);
            } catch (error) {
                console.log(error)
            }
        };

        fetchNotifications();
        const intervalId = setInterval(fetchNotifications, 10000);

        return () => clearInterval(intervalId);
    }, [])

    // Function to handle opening/closing the notification container
    const toggleNotifications = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            markAsRead();
        }
    };

    // Function to mark all notifications as read when the container is opened
    const markAsRead = () => {
        const unreadNotifications = notifs
            .filter((notification) => !notification.isRead)
            .map((notification) => notification.id);
        setUnreadCount(0)
        if (unreadNotifications.length > 0) {
            put_request(navigate, `/api/v1/notifications/${unreadNotifications}`, {}, user)
        }
    };

    // Function to delete a notification
    const deleteNotification = (id) => {
        const updatedNotifications = notifs.filter((notification) => notification.id !== id);
        setNotifications(updatedNotifications);
        delete_request(navigate, `/api/v1/notifications/${id}`, user);
    };

    return (
        <div className="notification-notifications"
             onMouseLeave={() => setIsOpen(false)}
        >
            <div className="notification-icon"
                 onClick={toggleNotifications}
            >
                <FaRegBell  style={{fontSize:"25px"}}/>
                {unrCount > 0 && (
                    <div className="notification-count">{unrCount > 99 ? '99+' : unrCount}</div>
                )}
            </div>
            {isOpen && (
                <div className="notification-container">
                    <div className="notification-header">bildirimler</div>
                    <div className="notification-items">
                        {notifs.length > 0 ? (
                            notifs.map((notification) => (
                                <div
                                    key={notification.id}
                                    className="notification-item"
                                    style={{ backgroundColor: notification.isRead ? '#ffffff' : '#f0f0f0' }}
                                >
                                    <span>{notification.message}</span>
                                    <button style={{color: "#bc2832"}} onClick={() => deleteNotification(notification.id)}>
                                        <FaRegTrashCan />
                                    </button>
                                </div>
                            ))
                        ) : (
                            <div className="notification-empty-notifications">
                                <div></div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Notifications;

import React, { useEffect, useState } from "react";
import classes from "./Timer.module.scss";
import { useTimer } from "react-timer-hook";

const Timer = (props) => {
  const expiryTimestamp = new Date(props.expiryTimestamp);
  console.log("timer renderlandı")

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

 useEffect(() => {
    // expiryTimestamp her değiştiğinde Timer'ı yeniden başlat
    if (expiryTimestamp.getTime() !== 0) {
      restart(expiryTimestamp);
    }
  }, [props.expiryTimestamp, restart]);

 return (
      <div
          className={classes.timer}
          style={{
            width: props.width ? props.width : null,
            backgroundColor: props.isBidded
                ? props.isOwned
                    ? "#9cd44f"
                    : "#f32f1e"
                : "none",
            color: !props.isBidded ? "black" : "white",
          }}
      >
        {days} gün {hours > 9 ? hours : "0" + hours}:
        {minutes > 9 ? minutes : "0" + minutes}:
        {seconds > 9 ? seconds : "0" + seconds}
      </div>
  );
};

export default React.memo(Timer);

import React, {useCallback, useEffect, useRef, useState} from "react";
import classes from "./AuctionProductCard.module.scss";
import Timer from "../auctions/Timer";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { formatPrice } from "../../utils/Formatters";
import {
  delete_request,
  post_request,
  get_request,
} from "../../utils/ApiHandlers";
import { FaRegHeart, FaHeart, FaCheck, FaTimes } from "react-icons/fa";
import toastr from "toastr";
import ConfirmationModal from "../modals/Comfirmation";


function useOutsideClick(ref, callback) {
  const handleClick = useCallback((e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  }, [ref, callback]);

  useEffect(() => {
    document.addEventListener("mouseout", handleClick);
    document.addEventListener("touchstart", handleClick);

    return () => {
      document.removeEventListener("mouseout", handleClick);
      document.removeEventListener("touchstart", handleClick);
    };
  }, [handleClick]);
}


const AuctionProductCard = (props) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const [isInWatchList, setIsInWatchList] = useState(props.isInWatchList);
  const [showBidOptions, setShowBidOptions] = useState(false);
  const [bidOptions, setBidOptions] = useState([]);
  const [selectedBid, setSelectedBid] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dropdownRef = useRef(null);

  const handleOutsideClick = useCallback(() => {
    setShowBidOptions(false);
    setSelectedBid(null);
  }, []);

  useOutsideClick(dropdownRef, handleOutsideClick);

  useEffect(() => {
    const priceElement = document.getElementById(props.id);
    if (priceElement) {
      priceElement.classList.add(classes.change);
    }

    setTimeout(() => {
      if (priceElement) {
        priceElement.classList.remove(classes.change);
      }
    }, 500);
  }, [props.currentPrice, props.isOwned, props.endTime]);

  const toggleWatchList = async () => {
    if (!user.loggedIn) return;

    if (isInWatchList) {
      const response = await delete_request(
        navigate,
        `/api/v1/watchlist/${props.id}`,
        user
      );
      if (response.status === 200) {
        setIsInWatchList(!isInWatchList);
      }
    } else {
      const response = await post_request(
        navigate,
        `/api/v1/watchlist/${props.id}`,
        user
      );
      if (response.status === 200) {
        setIsInWatchList(!isInWatchList);
      }
    }
  };

  const fetchBidOptions = async () => {
    console.log(props);
    const response = await get_request(
      navigate,
      `/api/v1/bids/price-options/${props.id}`,
      user
    );
    if (response.status === 200) {
      setBidOptions(response.data.prices);
      setShowBidOptions(true);
    }
  };

  const submitBid = async () => {
    setIsModalVisible(false)
    const response = await post_request(
      navigate,
      `/api/v1/bids`,
      { lotId: props.id, price: selectedBid },
      user
    );
    if (response.status === 200) {
      if (response.data.isSuccess) {
        toastr.success(response.data.message);
      } else {
        toastr.warning(response.data.message);
      }
      setShowBidOptions(false);
      setSelectedBid(null);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  }

  const handleClick = () => {
    setIsModalVisible(true);
  };


  return (
    <div
      className={classes.product_card}
      style={{ height: props.isCurrentAuction ? "65vh" : "45vh" }}
    >
      {props.isCurrentAuction && (
        <div
          className={classes.watchlistIcon}
          onClick={user.loggedIn ? toggleWatchList : () => {}}
          style={{
            borderColor: "#d3d3d3",
            cursor: user.loggedIn ? "pointer" : "not-allowed",
          }}
        >
          <i>
            {isInWatchList ? (
              <FaHeart color={"#f32f1e"} size={"1.2em"} />
            ) : (
              <FaRegHeart color={"#f32f1e"} size={"1.2em"} />
            )}
          </i>
        </div>
      )}
      <img
        onClick={() =>
          props.openImageFullScreen(props.imageUrls && props.imageUrls)
        }
        src={props.imageUrls && props.imageUrls[0].url}
        alt={props.imageUrls.alt ? props.imageUrls.alt : "görsel"}
        className={classes.product_card__img}
      />
      <span className={classes.text_bold}>lot {props.lotNumber}</span>
      <span className={classes.text_bold}>{props.artistFullName}</span>
      <span className={classes.text_bold}>{props.name}</span>
      <span className={classes.text_normal}>{props.description && props.description.length > 60? props.description.substring(0, 60) + "..." : props.description
      }</span>
      {props.isCurrentAuction ? (
          <>
            <Timer
                isBidded={props.isBidded}
                isOwned={props.isOwned}
                expiryTimestamp={props.endTime}
            />
            <span
                id={props.id}
                style={{
                  fontFamily: "Gilroy-SemiBold",
                  marginBottom: "2vh",
                  fontSize: 18,
                  letterSpacing: 1.5,
                }}
            >
            {formatPrice(props.currentPrice.toString())} TL
          </span>
            </>
      ) : ( user.loggedIn && props.bannerText && <div className={classes.bannerText}>{props.bannerText.toLocaleLowerCase('tr')}</div>)
      }
      {props.isCurrentAuction ? (
          <>
              <ConfirmationModal
                  isVisible={isModalVisible}
                  onConfirm={submitBid}
                  onCancel={handleCancel}
                  message={selectedBid && `"${props.name ? props.name : "untitled"}" adlı esere ${formatPrice(selectedBid.toString())} TL teklif vermeyi onaylıyor musunuz?`}
              />

      <div className={classes.product_card__btn_container}>
        {user.loggedIn ? (
            <>
              {props.isBiddingEnabled ? (
                  <>
                    {!showBidOptions && !props.isOwned && (
                        <button
                            className={classes.product_card__btn_container__btn}
                            onClick={fetchBidOptions}
                        >
                          hızlı teklif
                        </button>
                    )}
                    {showBidOptions &&
                        (selectedBid ? (
                            <button
                                className={classes.product_card__btn_container__btn}
                                onClick={() => setSelectedBid(null)}
                            >
                              {formatPrice(selectedBid.toString())} TL
                            </button>
                        ) : (
                            <div ref={dropdownRef} className={classes.bid_options}
                            >
                              {bidOptions.map((option, index) => (
                                  <span
                                      key={index}
                                      className={classes.bid_option}
                                      onClick={() => {
                                        setSelectedBid(option);
                                      }}
                                  >
                          {formatPrice(option.toString())} TL
                        </span>
                              ))}
                            </div>
                        ))}
                    {selectedBid && (
                        <div className={classes.confirm_buttons}>
                          <button
                              onClick={handleClick}
                              className={classes.product_card__btn_container__btn}
                          >
                            <FaCheck size={"1.2em"} color={"#28a745"}/>
                          </button>
                          <button
                              onClick={() => {
                                setSelectedBid(null);
                                setShowBidOptions(false);
                              }}
                              className={classes.product_card__btn_container__btn}
                          >
                            <FaTimes size={"1.2em"} color={"#dc3545"}/>
                          </button>
                        </div>
                    )}
                    <button
                        style={{
                          width: props.isOwned
                              ? "100%"
                              : !showBidOptions
                                  ? "47%"
                                  : "0px",
                          display: showBidOptions ? "none" : "flex",
                        }}
                        className={classes.product_card__btn_container__btn}
                        onClick={() =>
                            navigate(`/auctions/auction/product_details/${props.id}`)
                        }
                    >
                      eser detay
                    </button>

                  </>
              ) : (
                  <>
                    {props.bannerText &&
                        <div style={{marginTop: 0 }} className={classes.bannerText}>{props.bannerText.toLocaleLowerCase('tr')}</div>
                    }

                  </>
              )}
              <div className={classes.bidCount}>
                teklifler: {props.bidCount}
              </div>
            </>

        ) : (
            <button
                style={{
                  width: "100%",
                  //transform: "translateY(80%)",
              fontSize: "0.8rem",
            }}
            className={classes.product_card__btn_container__btn}
            onClick={() => navigate("/login")}
          >
            giriş gerekli
          </button>
        )}
            </div>
              </>
          ) : (
              !user.loggedIn && (
                  <div className={classes.product_card__btn_container}>
                    <button
                        style={{
                            width: "100%",
                            fontSize: "0.8rem",
                        }}
                        className={classes.product_card__btn_container__btn}
                        onClick={() => navigate("/login")}
                    >
                        giriş gerekli
                    </button>
                  </div>
              )
          )}

    </div>
  );
};

export default AuctionProductCard;
